<template>
	<div class="a-flex-rfsc a-ptb-12" >
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span>{{label}}</span>
		</div>
		<el-date-picker 
            :editable="editable" 
            :disabled='disabled' 
            :value-format="valueFormat" 
            :default-time="['00:00:00','23:59:59']"
            class="date-range a-ml-20" 
            :class="large?'date-range-large':'date-range'" 
            v-model="value1" :type="type"
			range-separator="-" 
            :start-placeholder="startPlaceholder" 
            :end-placeholder="endPlaceholder" 
            :pickerOptions="pickerOptions" 
            @change="handlerOnPick">
		</el-date-picker>
	</div>
</template>

<script>
	export default {
		props: {
			type:{
				type: String,
                default: 'datetimerange'
			},
            valueFormat:{
                type: String,
                default: 'yyyy-MM-dd'
            },
			label: {
				type: String
			},
			minDate: {
				required: false,
			},
			maxDate: {
				required: false,
			},
			startPlaceholder: {
				required: false,
				type: String,
				default: '开始日期'
			},
			endPlaceholder: {
				required: false,
				type: String,
				default: '结束日期'
			},
			/**
			 * 是否必填
			 */
			notnull: {
				type: Boolean,
				default: false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large: {
				type: Boolean,
				required: false,
				default: false
			},
            editable:{
                default: false
            },
            pickerOptions: {
				require: false
			},
            disabled: {
                default: false,
                type: Boolean
            }
		},
		data() {
			return {
				value1: []
			}
		},
        computed: {
            dateVal () {
                return this.minDate + this.maxDate
            }
        },
        watch: {
            dateVal:{
				immediate: true,
                handler (val) {
                    if(!val){
                        this.value1 = []
                    }else{
						this.$set(this.value1,0,this.minDate)
						this.$set(this.value1,1,this.maxDate)
					}
                }
            }
        },
		mounted() {

		},
		methods: {
			handlerOnPick(e) {
				this.$emit('update:minDate', e?e[0]:'')
				this.$emit('update:maxDate', e?e[1]:'')
			}
		}
	}
</script>

<style scoped>
	.date-range {
		width: 240px;
	}
	
	.date-range .el-range-input {
		width: 90px;
	}
	
	.date-range-large {
		width: 350px;
	}
	
	.date-range-large .el-range-input {
		width: 108px;
	}

</style>
