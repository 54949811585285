<template>
    <div class="scroll" :style="{height:getHeight}" @scroll="scrollChange" ref="parentScroll">
        <div class="scroll-content" :style="{width:getWidth}"></div>
    </div>
</template>
 
<script>
    /**
     *  表格顶部滚动条组件
     *  将表格的$refs传过来即可
     *
     *  <table-scroll :table-ref="$refs.invoiceTable"></table-scroll>
     *
     */ 
    export default {
        name: "TableScroll",
        data() {
            return {}
        },
        props: {
            tableRef: null
        },
        computed: {
            getWidth() {
                if (this.tableRef) {
                    return this.tableRef.bodyWidth;
                } else {
                    return 0;
                }
            },
            getHeight () {
                if (this.tableRef) {
                    return '20px';
                } else {
                    return 0;
                }
            }
        },
        methods: {
            scrollChange(ev) {
                let scrollLeft = this.$refs.parentScroll.scrollLeft;
                this.tableRef.bodyWrapper.scrollLeft = scrollLeft;
                this.$eventBus.$emit('tableScroll',scrollLeft)
            },
        }
    }
</script>
 
<style lang="scss" scoped>
    .scroll {
        // width: 100%;
        margin-bottom: 12px;
        overflow: auto;
        .scroll-content {
            height: 100%;
        }
    }
</style>