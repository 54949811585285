<template>
	<div class="a-h-100 my-menu" style="width: 232px; box-shadow: 0px 2px 10px 0px #EBEFF5; ">
		<el-menu class="el-menu-vertical-demo a-fs-14 a-c-333" style="overflow: hidden;">
			<el-submenu v-for="(item,index) in menu" :key="index" :index="index">
				<template slot="title">
					<img :src="item.icon" class="a-wh-16" />
					<span class="a-ml-08 ">{{item.name}}</span>
				</template>
				<template v-if="item.childMenu">
					<el-menu-item v-for="(childItem,childIndex) in item.childMenu" :key="childIndex" :index="index+''+childIndex">
						<span class="a-ml-08 ">{{childItem.name}}</span>
					</el-menu-item>
				</template>
			</el-submenu>
		</el-menu>
	</div>
</template>
<script>
	import {
		mapGetters,
		mapState,
		mapActions
	} from "vuex";

	export default {
		data() {
			return {
				
			};
		},

		computed: {
			...mapState({
				menu: state => state.menu.menu
			})
		},
		mounted() {
			// this.setMenu([
			// 	{
			// 		icon:require('../../../assets/icon/menu-order.png'),
			// 		name:'订单管理',
			// 		childMenu:[
			// 			{
			// 				name:'订单管理1',
			// 			}
			// 		]
			// 	},
			// 	{
			// 		icon:require('../../../assets/icon/menu-gongdan.png'),
			// 		name:'工单处理'
			// 	}
			// ])
		},
		methods: {
			...mapActions({
				setMenu: 'menu/setMenu'
			}),
		},
	};
</script>
<style lang="scss" scoped>
	.my-menu{
		height: calc(100vh - 84px);
		overflow-y: hidden;
	}
	::-webkit-scrollbar {
	
	  display: none; /* Chrome Safari */
	
	}
</style>
