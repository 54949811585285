<template>
    <div class="a-flex-rfsc">
        <!-- <el-image 
            class="md-icon"
            src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/pc/md-icon.png" 
            fit="fill" 
            :lazy="true"></el-image> -->
        <img class="md-icon" src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/md-icon3.0.png" alt="">
        <span class="mdNumber">{{ number }}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        };
    },

    props: {
        number: {
            default: 0
        }
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.md-icon{
    // transform: rotateY(180deg);
    width:20px;
    height:21px;
    margin: 0 8px
}
.mdNumber{
    font-size: 14px;
    color: #333;
}
</style>