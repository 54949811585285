<template>
	<div class="a-flex-rfsc a-ptb-12" >
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span>{{label}}</span>
		</div>

		<el-input  type="number" class="a-ml-20" :class="large?'s-input-number-large':'s-input-number'" :placeholder="placeholderMin||placeholder" :value="minData" @input="handlerChangeMin">
		</el-input>
		<span class="s-input-dis">-</span>
		<el-input type="number" :class="large?'s-input-number-large':'s-input-number'" :placeholder="placeholderMax||placeholder" :value="maxData" @input="handlerChangeMax">
		</el-input>
	</div>
	
</template>

<script>
	export default {
		props:{
			label:{
				type:String
			},
			minData:{
				required:true,
				default:''
			},
			maxData:{
				required:true,
				default:''
			},
			placeholder:{
				type:String,
				default:'请输入'
			},
			placeholderMin:{
				type:String,
			},
			placeholderMax:{
				type:String,
			},
			/**
			 * 是否必填
			 */
			notnull:{
				type:Boolean,
				default:false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large:{
				type:Boolean,
				required:false,
				default:false
			}
		},
		data() {
			return {
			}
		},
		methods:{
			handlerChangeMin(e){
				console.log('handlerChange',e)
				this.$emit('update:minData',e)
			},
			
			handlerChangeMax(e){
				console.log('handlerChange',e)
				this.$emit('update:maxData',e)
			}
		}
	}
</script>
