
export default {
	//用户信息
	getUserInfo:'api/user/user/userInfo',//获取用户信息
	getCompanyInfoNoCache:'api/company/company/getCompanyInfoNoCache',//获取公司信息
    getUserByPhone:'api/user/user/getUserByPhone',//获取目标用户信息

    // 人脸
    facePage:'api/user/face/back/page',//获取人脸认证列表
    faceAudit:'api/user/face/back/audit',//人脸认证审核
    faceInfo: 'api/user/face/front/details',//人脸认证信息
    faceAdd: 'api/user/face/back/add',//上传人脸认证

    // 车辆信息
    getUserBikeList: 'api/user/bike/getUserBikeList',//获取用户车辆列表
    saveBike: 'api/user/bike/saveBike',//保存用户车辆信息
    getApplyList: 'api/user/bike/back/getApplyList',//用户车辆审批列表
    applyUserBike: 'api/user/bike/back/applyUserBike',//用户车辆信息审批
    backUserChangeBike: 'api/user/bike/back/backUserChangeBike',//后台修改用户车辆信息
    getTimelineList: 'api/user/bike/back/getTimelineList',//用户车辆信息变更记录
    getUserBikeApplyDetail: 'api/user/bike/back/getUserBikeApplyDetail',//用户车辆审批中信息
    getUserBikeDetail: 'api/user/bike/back/getUserBikeDetail',//用户车辆信息
    getUserPowerInfo: 'api/user/bike/back/getUserPowerInfo',//查询用户30天充电信息
    
	//站点管理
	getStationInfoList: 'api/company/station/getStationInfoList',//获取站点管理列表
	getStationDetail: 'api/company/station/getStationDetail',//获取站点信息
	bindPlan:'api/company/station/bindPlan',//绑定站点定价
	setStationElectric:'api/company/station/setStationElectric',//设置站点参数
	changeStationName:'api/company/station/changeStationName',//更改站点名称
	changeStationCompanyId:'api/company/station/changeStationCompanyId',//修改站点所属商户
	changeStationOpCompanyId:'api/company/station/changeStationOpCompanyId',//修改站点运营方商户
	cancelCreateStation:'api/company/station/cancelCreateStation',//取消建站
	bindDevice:'api/device/device/back/bindDevice',//站点绑定设备
	getBanPayChannel:'api/company/station/getBanPayChannel',//站点可用支付方式
    
	
	//定价
	getPlanDetail:'api/company/plan/getPlanDetail',//获取定价明细
	getPlanPower:'api/company/plan/getPlanPower',//获取定价明细
	getPlanUserStationSize:'api/company/plan/getPlanUserStationSize',//获取定价相关站点数
	delPlan:'api/company/plan/delPlan',//删除定价
	createPlan:'api/company/plan/createPlan',//新建定价
	editPlan:'api/company/plan/editPlan',//修改定价
	getPlanRule:'api/company/plan/getPlanRule',//修改定价
	getPowerServiceDetail:'api/company/plan/getPowerServiceDetail',//功率服务费详情
    getDeviceChargePlan:'api/device/device/queryDeviceChargePlan',//获取站点定价
    
    
	
	getUnderCompany: 'api/company/company/getUnderCompany',//获取隶属于本商户的商户列表,用户商户选择
	getAllCompany: 'api/company/company/getAllCompany',//获取所有商户列表,用于商户选择
	getNoAgencyCompany: 'api/company/company/getNoAgencyCompany',//获取没有签署加盟商协议的商户,用于商户选择
    getUnderAndUpCompany: 'api/company/company/getUnderAndUpCompany',//获取本商户的下级商户列表和直属上级商户,用于商户选择
	getIncomeStatInfoList: 'api/company/incomeDistri/getIncomeStatInfoList',//项目管理-获取方案视图统计列表
	getIncomeStatByStationInfoList: 'api/company/incomeDistri/getIncomeStatByStationInfoList',//获取站点视图统计列表
	uploadFile: 'api/company/file/aliOssFileUpload',//上传文件
	getSurveyArea: 'api/company/survey/getSurveyArea',//获取现勘小区信息
	getSurveyStation: 'api/company/survey/getSurveyStation',//获取现勘站点信息

	getDistriSelectList: 'api/company/incomeDistri/getDistriSelectList',//获取收益划分方案选择列表
	getJoinDistriSelectList: 'api/company/incomeDistri/getJoinDistriSelectList',//根据当前登录商户的获取参与收益划分方案选择列表
	getPlanList: 'api/company/plan/getPlanList',//获取定价列表
	createStation: 'api/company/station/createStation',//申请站点
	getLocationCode:'api/company/map/getLocationCode',//地址逆解析

	//项目管理
	createIncome:'api/company/incomeDistri/createIncome',//创建收益划分方案
	getCompanyMaxRate:'api/company/agency/getCompanyMaxRate',//获取最大可分配收益比例
	getIncomeDetailInfo:'api/company/incomeDistri/getIncomeDetailInfo',//获取收益方案详细信息
    getStatOrderAmoutList:'api/company/stat-pc/getStatOrderAmountList',//获取收益趋势
    getStatOrderAmountTotal:'api/company/stat-pc/getStatOrderAmountTotal',//获取收益趋势总计
    getStatEnergyTotal:'api/company/stat-pc/getStatEnergyTotal',//获取电量统计总计
    getStatEnergyList:'api/company/stat-pc/getStatEnergyList',//获取电量统计
    getStationInfoList:'api/company/station/getStationInfoList',//获取站点管理列表
    getIncomeChangeRecord:'api/company/incomeDistri/getIncomeChangeRecord',//获取变更记录
    getIncomeTimeLine:'api/company/incomeDistri/getIncomeTimeLine',//获取收益方案操作历史
    gitEditIncomeDetailInfo:'api/company/incomeDistri/getEditIncomeDetailInfo',//获取编辑页面收益方案详细信息
    changeIncome:'api/company/incomeDistri/changeIncome',//提交变更方案
    notAgreeIncome:'api/company/incomeDistri/notAgreeIncome',//对方案提出异议
    cancelIncome:'api/company/incomeDistri/cancelIncome',//取消方案
    confirmIncome:'api/company/incomeDistri/confirmIncome',//确认方案
    delIncome:'api/company/incomeDistri/delIncome',//删除项目
    getIncomePlanList: 'api/income/incomePlan/getPlanList',//获取分账模板
    changeContractFile:'api/company/incomeDistri/changeContractFile',//变更方案合同附件

    //订单管理
    getOrderInfoById:'api/order/orderQuery/back/getOrderInfoById',//订单详情
    getOrderPower:'api/order/orderQuery/getOrderPower',//获取订单的功率记录
    refundOrder:'api/order/order/refundOrder',//订单开始退款
    getOrderList: 'api/company/order/getOrderList',//订单列表
    superPlaceOrder:'api/order/order/backSuperPlaceOrder',//统一下单
	payOrder:'api/order/order/backPayOrder',//下单支付
    getPayResult:'api/order/order/getOrderStatus',//查询支付结果
    superOrderPage:'api/order/order/superOrderPage',//查询套餐订单、商品订单列表
    superOrderRefund:'api/order/order/refund',//查询套餐订单、商品订单退款
    superOrderDetails:'api/order/order/back/orderDetails',//查询套餐订单、商品订单详情
    waterOrderList:'api/order/water/order/list',//分页查询打水订单列表
    waterOrderRefund:'api/order/water/order/refund',//打水订单退款
    cabinetOrderPage:'api/order/chargingBox/back/page',//充电柜订单列表
    cabinetOrderInfo:'api/order/chargingBox/details',//充电柜订单详情
    cabinetOrderRefund:'api/order/chargingBox/back/refund',//充电柜订单退款
    cabinetEnergyList:'api/order/chargingBox/energyList',//充电柜订单电量图表
    cabinetCloserOrder:'api/order/chargingBox/back/closerOrder',//充电柜关闭订单-关电
    socketCloserOrder:'api/order/order/back/closerOrder',//充电桩关闭订单-关电
    orderRepay:'api/order/order/rePay',//订单手动扣款
    orderReFoundRetry:'api/order/order/retryRefund',//订单退款重试
    orderRefundPoints:'api/order/order/refundPoints',//订单退毛豆
    getSuperOrderStatus:'api/order/order/getSuperOrderStatus',//查询支付结果
    remainPoints:'api/user/points/remainPoints',//查询套餐订单当前剩余毛豆
    couponOrderPage:'api/order/orderQuery/couponOrderPage',//查询优惠券订单列表
    couponOrderDetail:'api/order/orderQuery/couponOrderDetail',//查询优惠券订单详情
    addGoods:'api/order/goods/addGoods',//添加商品
    
    

    //待办事项
    getWithDoneList:'api/company/notify/getWithDoneList',//获取待办事项列表
    getWithDoneDealNum:'api/company/notify/getWithDoneDealNum',//获取待办事项待处理总数

    //消息
    readNotify:'api/company/notify/pc_readNotify',//标记消息已读
    getNotifyList:'api/company/notify/getNotifyList',//获取消息列表
    getNotifyPreview:'api/company/notify/getNotifyPreview',//获取我的消息预览信息
    getMyNotReadMessageCount:'api/company/notify/getMyNotReadMessageCount',//获取我的未读消息数
    getNotifyNum:'api/company/notify/getNotifyNum',//获取消息总数

    //充电卡管理
    chargeCardList:'api/company/pc_card/cardList',//充电卡列表（基础信息）
    onLineCardList:'api/company/pc_card/onLineCardList',//在线卡列表
    offLineCardList:'api/company/pc_card/offLineCardList',//离线卡列表
    addOffLineCard:'api/company/pc_card/add_offLineCard',//新增离线卡
    unbindUserCard:'api/user/userCard/unbindUserCard',//在线卡解绑
    chargeAddCard:'api/company/pc_card/add_card',//新增卡(基础)
    chargeCardBatchImport:'api/company/pc_card/card_batchImport',//实体基础卡批量导入
    importUserCard:'api/user/balance/importUserCard',//实体基础卡批量导入
    getUserECard:'api/user/userCard/getUserECard',//获取电子卡片
    updateCard:'api/company/pc_card/updateCard',//s设置卡片信息
    updateUserCard:'api/user/userCard/back/updateUserCard ',//s设置卡片信息
    
    

    //客户管理
	getCompanyManagerList: 'api/company/company/getCompanyManagerList',  //获取客户列表
    authCompany:'api/company/company/authCompany',//商户入驻审核
    getCompanyManagerInfo:'api/company/company/getCompanyManagerInfo',//获取客户管理详细信息
    adminOcrForCompany:'api/user/user/adminOcrForCompany',//注册商户实名认证
    substituteRegisterPc:'api/company/register/substituteRegister-pc',//代注册
    registerCompany:'api/company/register/registerCompany',//注册商户

    //首页
    slotUsedInfo:'api/company/pc_appStat/slotUsedInfo',//获取使用情况(插槽)
    payType:'api/company/pc_appStat/payType',//支付方式
    orderNumber:'api/company/pc_appStat/orderNumber',//订单量
    deviceUsedInfo:'api/company/pc_appStat/deviceUsedInfo',//获取使用情况(设备)
    deviceSlot:'api/company/pc_appStat/deviceSlot',//设备插槽
    companyTurnoverRank:'api/company/pc_appStat/companyTurnoverRank',//本月商户营业额排行
    chargingDeviceInstall:'api/company/pc_appStat/chargingDeviceInstall',//各地区充电桩安装/拆除情况
    businessVolume:'api/company/pc_appStat/businessVolume',//获取营业额
    searchStation:'api/company/pc_appStat/searchStation',//搜索充电桩点位
    searchStationForDataShow:'api/company/pc_appStat/searchStationForDataShow',//搜索充电桩点位(数据大屏)
    getStatEnergyList:'api/company/stat-pc/getStatEnergyList',//获取电量统计
    getNotifyList:'api/company/notify/getNotifyList',//获取消息列表
    // getBikeTrendsIndex:'api/company/pc_appStat/bike/trends',//获取车库车辆趋势-首页
    getBikeTrendsIndex:'api/stat/statCompany/bike/trends',//获取车库车辆趋势-首页
    getIncomeTrendsIndex:'api/company/pc_appStat/income/trend',//获取收益趋势-首页
    
    
    //采购管理
    goodsOrderlist:'api/company/goodsOrder/list',//商品订单列表
    purchaseChargerPage:'api/company/purchase/page',//采购充电桩列表
    purchaseChargerSend:'api/company/purchase/send',//采购充电桩发货
    purchaseChargerExecute:'api/company/purchase/execute',//采购充电桩执行
    checkDeviceCode:'api/company/purchase/checkDeviceCode',//采购充电桩校验设备号
    purchaseChargerAdd:'api/company/purchase/add',//采购充电桩新增or编辑
    purchaseChargerDetail:'api/company/purchase/detail',//采购充电桩详情
    purchaseChargerDevList:'api/company/purchase/deviceList',//采购充电桩设备状态列表
    
    

    //用户管理
    getUserManagerList:'api/user/user-manager/getUserManagerList',//获取用户管理列表
    getUserManagerDetail:'api/user/user-manager/getUserManagerDetail',//获取用户管理详情
    getUserChargeBalanceInfoList:'api/user/user-manager/getUserChargeBalanceInfoList',//获取用户充值记录列表
    userCardRechargeRecord:'api/user/userCard/rechargeRecord',//用户实体卡充值记录
    userMdRechargeRecord:'api/user/points/rechargeRecord',//用户储值卡充值记录
    getUserConsumeList:'api/user/user-manager/getUserConsumeList',//获取用户消费记录
    getTransfersList:'api/user/balance/getTransfersList',//获取用户提现记录
    userCardListPC:'api/user/userCard/userCardList-pc',//获取用户实体卡列表PC管理端
    importUserCoupon:'api/user/balance/importUserCoupon',//导入红包
    pointsConsumeRecord:'api/user/points/pointsConsumeRecord',//用户毛豆消费记录
    pointsStationDetail:'api/user/points/pointsStationDetail',//用户毛豆站点记录
    userLockOrderInfo:'api/user/balance/userLockOrderInfo',//获取用户冻结订单信息
    getUserInfoByMobile:'api/user/user/getUserInfoByMobile',//获取/创建用户信息
    userWhiteOrBlackPage:'api/user/white/page',//黑白名单用户列表
	getWhiteOperateRecord:'api/user/white/getWhiteOperateRecord',//黑白名单操作记录列表
	userWhiteOrBlackDetails:'api/user/white/getDetail',//黑白名单用户详情
	userWhiteOrBlackChangeStatus:'api/user/white/changeStatus',//黑白名单用户状态更改
	addUpdateWhite:'api/user/white/addUpdateWhite',//添加黑白名单用户
    setChargingParams:'api/user/user-manager/setChargingParams',//更新用户的设置
    userCouponList:'api/user/coupon/userCouponList',//用户优惠券列表

    //登录
    mobileLogin:'api/user/user/mobile-login',//PC端手机号登录
    getDefaultCompanyInfo:'api/company/company/getDefaultCompanyInfo',//获取当前用户默认商户
    mobileCode:'api/user/verifyCode/send/mobileCode',//获取手机验证码
    resetPassword:'api/user/user/reset-password',//获取修改
    

    // 设备管理
    getDeviceType: 'api/device/device/getDeviceTypeList',// 获取设备类型
    getDeviceList: 'api/device/device/page',// 获取设备列表
    editDeviceType: 'api/company/device/editDeviceType',// 编辑设备类型
    getDeviceTypeDetail: 'api/company/device/getDeviceTypeDetail',// 获取设备类型详情
    getDeviceBaseInfo: 'api/device/device/getDeviceDetails',// 获取设备详情
    queryDeviceInfo: 'api/device/device/queryDeviceInfo',// 获取设备编辑详情
    getDeviceRealTimeInfo: 'api/device/device/getDeviceStatus',// 获取设备实时信息
    getDeviceRealTimeInfoManual: 'api/device/iot/sense',// 获取设备实时信息(主动检测)
    getDeviceOptionTimeline: 'api/device/device/getDeviceOptionTimeline',// 获取设备绑定解绑记录
    getDeviceOnlineRecord: 'api/device/device/getDeviceOnlineRecord',// 获取设备上线离线记录
    batchUnBindDevice: 'api/device/device/batchUnBindDevice',// 批量解绑设备
    batchSetDeviceStopUse: 'api/device/device/batchSetDeviceStopUse',// 批量停用设备
    batchSetDeviceCanUse: 'api/device/device/batchSetDeviceCanUse',// 批量启用设备
    openDoor: 'api/device/device/back/openDoor',// 门禁设备 开门
    batchReboot: 'api/device/iot/batch-reboot',// 批量重启设备
    getDeviceEvent: 'api/device/device/getDeviceEvent',// 获取设备事件记录
    getDeviceLog: 'api/device/device/getDeviceLog',// 获取设备流水日志
    addDevice: 'api/device/device/addDevice',// 添加设备
    editDevice: 'api/device/device/editDevice',// 编辑设备
    getJdDeviceDefaultParam: 'api/device/param/getJdDeviceDefaultParam',// 获取'二路'类型设备默认参数 (1,2类型)
    getJdDeviceParam: 'api/device/param/getJdDeviceParam',// 获取'二路'类型设备参数 (1,2类型)
    getYd10DeviceDefaultParam: 'api/device/param/getYd10DeviceDefaultParam',// 获取'十路'类型设备默认参数 (5类型)
    getYd10DeviceParam: 'api/device/param/getYd10DeviceParam',// 获取'十路'类型设备参数 (5类型)
    getYd12DeviceDefaultParam: 'api/device/param/getYd12DeviceDefaultParam',// 获取'十二路'类型设备默认参数 (3,4类型)
    getYd12DeviceParam: 'api/device/param/getYd12DeviceParam',// 获取'十二路'类型设备参数 (3,4类型)
    batchParams: 'api/device/param/batch-param',// 批量下发参数
    batchImportDev: 'api/device/device/batchImport',// 批量导入
    getDeviceCategroyList: 'api/device/device/getDeviceCategroyList',//获取设备种类
    devRemoteCharge: 'api/device/device/back/addOrder',//远程开电（远程创建充电订单）
    getDeviceCardPremission: 'api/device/device/back/getDeviceCardPremission',//设备硬件权限
    getDeviceOnlinePage: 'api/device/count/onlinePage',//获取设备在线记录
    recordDeviceTransplant: 'api/device/iot-park-wg/recordDeviceTransplant',//设备权限复制重设
    cabinetRemoteCharge: 'api/device/deviceCharge/beginCharge',//充电柜设备远程开电
    cabinetRemoteOpen: 'api/device/deviceCharge/openDoor',//充电柜设备远程远程开门开电
    cabinetRemoteOpenOnly: 'api/device/deviceCharge/openDoorOnly',//充电柜设备远程仅开门
    deviceStopCharge: 'api/device/deviceCharge/stopCharge',//设备远程停止充电
    devsense:'api/device/deviceCharge/sense',//查询设备状态（插座）
    deviceSense:'api/device/deviceCharge/doorStatus',//查询设备状态(充电柜，充电舱)
    deviceUpdateNormal: 'api/device/device/back/deviceUpdateNormal',// 设备升级
    deviceSetvolume: 'api/device/deviceCharge/setvolume',// 设备设置音量
    
    
    
    // 经营分析
    getStatProvinceList: 'api/company/stat-pc/getStatProvinceList',// 获取经营分析列表（按省份）
    getStatIncomeList: 'api/company/stat-pc/getStatIncomeList',// 获取经营分析列表（按项目）
    getStatCompanyList: 'api/company/stat-pc/getStatCompanyList',// 获取经营分析列表（按商户）
    getStatCityList: 'api/company/stat-pc/getStatCityList',// 获取经营分析列表（按城市）
    getStatStationList: 'api/company/stat-pc/getStatStationList',// 获取经营分析列表（按站点）
    getStatOrderAmountTotal: 'api/company/stat-pc/getStatOrderAmountTotal',// 获取收益趋势总计
    getStatOrderAmountList: 'api/company/stat-pc/getStatOrderAmountList',// 获取收益趋势图表
    getStatDeviceAmountDistriList: 'api/company/stat-pc/getStatDeviceAmountDistriList',// 获取单桩收益分布表格
    getStatDeviceAmountDistriDetailList: 'api/company/stat-pc/getStatDeviceAmountDistriDetailList',// 获取单桩收益站点分布图表
    getStatOrderTimeDistriList: 'api/stat/statStation/orderTimeList',// 获取设备充电时长分布图表
    getStatOfflineDeviceList: 'api/company/stat-pc/getStatOfflineDeviceList',//  获取设备离线天数分布
    getStatOfflineDeviceList2: 'api/company/stat-pc/getStatOfflineDeviceList2',// 获取设备情况
    getStatDeviceInstallNumList: 'api/company/stat-pc/getStatDeviceInstallNumList',// 获取设备安装趋势图表
    getStationAnalysisDetail: 'api/company/station/getStationAnalysisDetail',// 获取站点电费统计
    getStationIncomeInfo: 'api/company/stat-pc/car/stationInfo',// 获取车库站点收益信息
    
    getStationMonthcard: 'api/company/stat-pc/monthcard/distribute',// 获取车库站点套餐分布
    getStationIncomeDistribute: 'api/company/stat-pc/car/income/distribute',// 获取车库站点收益分布
    getStationTransactionDistribute: 'api/company/stat-pc/car/transaction/distribute',// 获取车库站点交易金额分布
    getStationIncomeTrend: 'api/company/stat-pc/income/trend',// 获取车库站点收益趋势
    getIncomeMonthCard: 'api/company/pc_appStat/income/distribute',// 获取收益分布（首页套餐）
    getTransitionMonthCard: 'api/company/pc_appStat/transaction/distribute',// 获取交易分布（首页套餐）
    getTransitionStationMonthCard: 'api/company/stat-pc/transaction/distribute',// 获取车库站点交易分布（站点套餐）
    getIncomeStationMonthCard: 'api/company/stat-pc/income/distribute',// 获取车库站点收益分布（站点套餐）
    

    // 首页数据中心-new
    getIndexDataCenter: 'api/stat/statCompany/stat/dateCenter',// 获取首页数据中心统计数据
    getIndexDataCenterPayType: 'api/stat/statCompany/stat/dateCenter/payType',// 获取首页数据中心支付渠道环状图
    

    // 经营分析-商户-new
    getAnalysisBusinessPage: 'api/stat/statCompany/page',// 获取经营分析商户列表
    getAnalysisParkTjBusiness: 'api/stat/statCompany/car/amount/info',// 获取经营分析车库统计（商户）
    getAnalysisParkDistriBusiness: 'api/stat/statCompany/car/amount',// 获取经营分析车库统计饼状图（商户）
    getAnalysisParkBusinessMonthCard: 'api/stat/statCompany/car/monthCard',// 获取经营分析车库车辆套餐类型饼状图（商户）
    getAnalysisIncomeTrendBusiness: 'api/stat/statCompany/income/trend',// 获取经营分析收益趋势(商户)
    getAnalysisMonthCardTypeBusiness: 'api/stat/statCompany/monthCardType',// 获取经营分析套餐收益交易(商户)
    getAnalysisOrderTypeBusiness: 'api/stat/statCompany/orderType/amount',// 获取经营分析订单收益交易(商户)
    getAnalysisRevenueBusiness: 'api/stat/statCompany/incomeTotal',// 获取经营分析营收统计(商户)
    getAnalysisTransitionTrendBusiness: 'api/stat/statCompany/transaction/trend',// 获取经营分析交易额趋势(商户)
    getAnalysisDevOfflineBusiness: 'api/stat/statCompany/offlineDevice',// 获取经营分析设备离线天数趋势(商户)
    getAnalysisPointsTrendBusiness: 'api/stat/statCompany/pointsTrend',// 获取经营分析毛豆消耗趋势(商户)
    
    // 经营分析-项目-new
    getAnalysisIncomePage: 'api/stat/statIncome/page',// 获取经营分析项目列表
    getAnalysisParkTjIncome: 'api/stat/statIncome/car/amount/info',// 获取经营分析车库统计（项目）
    getAnalysisParkDistriIncome: 'api/stat/statIncome/car/amount',// 获取经营分析车库饼状图（项目）
    getAnalysisParkIncomeMonthCard: 'api/stat/statIncome/car/monthCard',// 获取经营分析车库车辆套餐类型饼状图（项目）
    getAnalysisIncomeTrendIncome: 'api/stat/statIncome/income/trend',// 获取经营分析收益趋势(项目)
    getAnalysisMonthCardTypeIncome: 'api/stat/statIncome/monthCardType',// 获取经营分析套餐收益交易(项目)
    getAnalysisOrderTypeIncome: 'api/stat/statIncome/orderType/amount',// 获取经营分析订单收益交易(项目)
    getAnalysisRevenueIncome: 'api/stat/statIncome/incomeTotal',// 获取经营分析营收统计(项目)
    getAnalysisTransitionTrendIncome: 'api/stat/statIncome/transaction/trend',// 获取经营分析交易额趋势(项目)
    getAnalysisDevOfflineIncome: 'api/stat/statIncome/offlineDevice',// 获取经营分析设备离线天数趋势(项目)
    getAnalysisPointsTrendIncome: 'api/stat/statIncome/pointsTrend',// 获取经营分析毛豆消耗趋势(项目)
    // getStationBikeTrends: 'api/company/stat-pc/bike/trends',// 获取车库站点车辆趋势
    getIncomeBikeTrends: 'api/stat/statIncome/bike/trends',// 获取车库站点车辆趋势

    // 经营分析-站点-new
    getAnalysisStationPage: 'api/stat/statStation/page',// 获取经营分析站点列表
    getAnalysisParkTjStation: 'api/stat/statStation/car/amount/info',// 获取经营分析车库统计（站点）
    getAnalysisParkDistriStation: 'api/stat/statStation/car/amount',// 获取经营分析车库饼状图（站点）
    getAnalysisParkStationMonthCard: 'api/stat/statStation/car/monthCard',// 获取经营分析车库车辆套餐类型饼状图（站点）
    getAnalysisDevInfo: 'api/stat/statStation/deviceInfo',// 获取经营分析站点设备情况
    getAnalysisIncomeTrendStation: 'api/stat/statStation/income/trend',// 获取经营分析收益趋势(站点)
    getAnalysisMonthCardTypeStation: 'api/stat/statStation/monthCardType',// 获取经营分析套餐收益交易(站点)
    getAnalysisOrderTypeStation: 'api/stat/statStation/orderType/amount',// 获取经营分析订单收益交易(站点)
    getAnalysisRevenueStation: 'api/stat/statStation/incomeTotal',// 获取经营分析营收统计(站点)
    getAnalysisTransitionTrendStation: 'api/stat/statStation/transaction/trend',// 获取经营分析交易额趋势(站点)
    getAnalysisDevOfflineStation: 'api/stat/statStation/offlineDevice',// 获取经营分析设备离线天数趋势(站点)
    getAnalysisPointsTrendStation: 'api/stat/statStation/pointsTrend',// 获取经营分析毛豆消耗趋势(站点)
    getStationBikeTrends: 'api/stat/statStation/bike/trends',// 获取车库站点车辆趋势

    // 经营分析-省市-new
    getAnalysisCityPage: 'api/stat/statProvince/page',// 获取经营分析站点列表(省市维度)
    getAnalysisParkTjCity: 'api/stat/statProvince/car/amount/info',// 获取经营分析车库统计（省市维度）
    getAnalysisParkDistriCity: 'api/stat/statProvince/car/amount',// 获取经营分析车库饼状图（省市维度）
    getAnalysisParkCityMonthCard: 'api/stat/statProvince/car/monthCard',// 获取经营分析车库车辆套餐类型饼状图（省市维度）
    getAnalysisIncomeTrendCity: 'api/stat/statProvince/income/trend',// 获取经营分析收益趋势(省市维度)
    getAnalysisMonthCardTypeCity: 'api/stat/statProvince/monthCardType',// 获取经营分析套餐收益交易(省市维度)
    getAnalysisOrderTypeCity: 'api/stat/statProvince/orderType/amount',// 获取经营分析订单收益交易(省市维度)
    getAnalysisRevenueCity: 'api/stat/statProvince/incomeTotal',// 获取经营分析营收统计(省市维度)
    getAnalysisTransitionTrendCity: 'api/stat/statProvince/transaction/trend',// 获取经营分析交易额趋势(省市维度)
    getAnalysisDevOfflineCity: 'api/stat/statProvince/offlineDevice',// 获取经营分析设备离线天数趋势(省市维度)
    getAnalysisCityBikeTrends: 'api/stat/statProvince/bike/trends',// 车库车辆趋势(省市维度)
    

    getStatOrderPermission: 'api/stat/statData/getStatPermission',// 获取设备统计权限

    // 站点观察
    getObserveList: 'api/stat/observe/list',// 获取站点观察列表（快速查询）
    addObserveStation: 'api/stat/feign/addObserveStation',// 站点入驻观察
    getObserveStationInfo: 'api/stat/observe/getObserveStationInfo',// 获取站点观察信息
    delObserveStation: 'api/stat/observe/delObserveStation',// 删除站点观察信息
    getObservePage: 'api/stat/observe/page',// 获取站点观察列表
    getObserveDetails: 'api/stat/observe/details',// 获取站点观察详情
    getObserveIncomeDay: 'api/stat/observe/incomeDay',// 观察-经营统计
    getObserveGradeDay: 'api/stat/observe/gradeDay',// 观察-达标统计
    getObserveStationPage: 'api/stat/observe/station/list',// 观察-站点分析
    getObserveAdd: 'api/stat/observe/add',// 观察编辑
    getObserveDetail: 'api/stat/observe/detail',// 观察详情
    updateObserveGrade: 'api/stat/observe/updateObserveGrade',// 站点观察修改达标值
    

    // 收益账单
    getBalanceDateDetail: 'api/company/bill/getBalanceDateDetail-pc',// 获取收益账单详情
    getBalanceDateList: 'api/company/bill/getBalanceDateList-pc',// 获取收益账单列表
    getStationListByBill: 'api/company/bill/getStationListByBill',// 获取收益账单站点列表

    // 现勘管理
    getSurveyAreaDetail: 'api/company/survey/getSurveyAreaDetail',// 获取现勘详情
    checkSurveyStation: 'api/company/survey/checkSurveyStation',// 现勘站点审核
    getSurveyStationDetail: 'api/company/survey/getSurveyStationDetail',// 现勘站点详情
    
    
    
    // 商户中心
    getCompanyInfoNoCache: 'api/company/company/getCompanyInfoNoCache',// 获取公司信息（没有缓存）
    getMineStat: 'api/company/stat/getMineStat',// 获取商户累计用户、本月新增用户、累计站点、本月新增站点
    getUnConfirmAmount: 'api/company/company/getUnConfirmAmount',// 获取商户未入账金额
    getBalanceChangeList: 'api/company/balance/getBalanceChangeList',// 获取商户收入明细
    withdrawSuccessList: 'api/company/withdraw/withdrawSuccessInfo',// 获取商户提现成功明细    
    bindBankAccount: 'api/company/companybank/bindBankAccount',// 绑定银行卡   
    bindBankAccountNew: 'api/company/companybank/bindBankAccountNew',// 绑定银行卡(new)
    unbindBankAccount: 'api/company/companybank/unbindBankAccount',// 解绑银行卡   
    getBankAccountList: 'api/company/companybank/getBankAccountList',// 获取银行账户列表
    getTodayWithdrawAmount: 'api/company/withdraw/getTodayWithdrawAmount',// 获取今日已申请提现金额
    applyWithdraw: 'api/company/withdraw/applyWithdraw',// 提交提现申请
    getWithdrawList: 'api/company/withdraw/getWithdrawList',// 提现申请记录
    chooseCompany: 'api/company/company/chooseCompany',// 提现申请记录
    getUserAccountStatus: 'api/pay/company-tl/getCompanyAccountStatus',// 通联交易权限 状态获取
    getCompanyAccount: 'api/pay/company-tl/pc/getCompanyAccount',// 通联交易权限信息
    getCompanyRelevanceCompanyList: 'api/income/back/income/getCompanyListByCompanyId',// 获取某商户项目关联商户
    

    // 开户行
    getDictBankList: 'api/company/dictPc/getDictBank',// 获取开户行
    getDictBankProvince: 'api/company/dictPc/getProvince',// 获取开户行所在省份
    getDictBankBranchList: 'api/company/dictPc/getBranchList',// 获取开户行支行
    getDictBankArea: 'api/company/dictPc/getArea',// 获取开户行所在地区

    // 微信银行api
    getWXBankBranches: 'api/pay/weixin/bank/branches',// 对公银行支行查询
    getWXBankCities: 'api/pay/weixin/bank/cities',// 查询银行所在市
    getWXBankProvinces: 'api/pay/weixin/bank/provinces',// 查询银行所在省
    getWXBankCorporate: 'api/pay/weixin/bank/jd/corporate',// 对公银行查询
    getWXBankPersonal: 'api/pay/weixin/bank/jd/personal',// 对私银行查询
    wxUploadFile: 'api/pay/weixinService/uploadFile',// 获取图片的微信mediaId    
    getUrlSign: 'token/wx/mini/getUrlSign',// 获取微信js sdk 网页校验

    
    //提现管理
    getWithdrawManagerList: 'api/company/withdraw/getWithdrawManagerList',// 获取提现管理记录  post/
    getWithdrawDetail: 'api/company/withdraw/getWithdrawDetail',// 获取提现详情 get/
    authWithdraw: 'api/company/withdraw/authWithdraw',// 提现申请审核 post/
    tradeWithdrawByTl: 'api/company/withdraw/tradeWithdrawByTl',// 提现通联打款接口 get/
    closeWithdraw: 'api/company/withdraw/closeWithdraw',// 提现撤销接口 get/
    doTradeByOffline: 'api/company/withdraw/doTradeByOffline',//上传打款凭证,并完成打款 get/
    applyTradeByOffline: 'api/company/withdraw/applyTradeByOffline',//申请线下转账操作
    
    //商户信息
    getOtherCompanyInfoNoCache:'api/company/company/getOtherCompanyInfoNoCache',//获取其他公司信息 get/
    getCompanyChooseList:'api/company/company/getCompanyChooseList',//选择商户页面获取商户列表 get/

    //加盟商
    signAgency:'api/company/agency/signAgency',//签署代理合同 POST
    getAgencyList:'api/company/agency/getAgencyList',//获取代理商列表 POST
    confirmAgency:'api/company/agency/confirmAgency',//确认合同 POST
    changeAgency:'api/company/agency/changeAgency',//修改代理合同 POST
	getTopCompanyIdWithCompanyId:'api/company/agency/getTopCompanyIdWithCompanyId',//获取顶级服务商ID get
    getTopCompanyId:'api/company/agency/getTopCompanyId',//获取顶级服务商ID get
    getSigningAgencyDetail:'api/company/agency/getSigningAgencyDetail',//获取签署中代理合同详情 get
    getMyAgencyLevel:'api/company/agency/getMyAgencyLevel',//获取商户的代理层级 get
    getAgencyMaxRate:'api/company/agency/getCompanyMaxRate',//获取最大可分配收益比例 get
    getAgencyDetail:'api/company/agency/getAgencyDetail',//获取详情中-代理合同详情 get
    cancelSignAgency:'api/company/agency/cancelSignAgency',//取消签署代理合同或者取消合同变更 get
    getAgencyTimeLine:'api/company/agency/getAgencyTimeLine',//获取代理合同操作历史 get
    delAgency:'api/company/agency/delAgency',//解除加盟商关系 get
    addFinanceMonthCardAuth:'api/company/company/addFinanceMonthCardAuth',//独营商户开启套餐权限
    getFinanceCompanySelect:'api/company/company/getFinanceCompany',//独营商户查询（用户下拉框选择）
    authBank:'api/company/companybank/authBank',//银行卡认证
    companyAdviceList:'api/user/userCooperation/companyAdviceList',//加盟合作列表
    companyAdviceRemark:'api/user/userCooperation/companyAdviceRemark',//加盟合作备注

    // 商户进件
    merchantPage:'api/company/merchant/page',//商户进件列表
    merchantDetails:'api/company/merchant/details',//商户进件详情
    merchantApplyState:'api/company/merchant/applyState',//商户进件状态查询
    merchantAdd:'api/company/merchant/add',//商户进件
    merchantOpenServiceProfit:'api/company/company/openServiceProfit',//商户开通独立钱包
    

    // 站点管理
    getStationDetail:'api/company/station/getStationDetail',//获取站点详细信息
	authStation:'api/company/station/authStation',//站点审核
	cancelCreateStation:'api/company/station/cancelCreateStation',//取消建站
	editStation:'api/company/station/editStation',//修改站点申报信息
	getStationBuildStatus:'api/company/station/getStationBuildStatus',//获取站点建设进度
	getStationDeviceShipInfo:'api/company/station/getStationDeviceShipInfo',//获取设备出库详细信息
	getStationBuildInfo:'api/company/station/getStationBuildInfo',//获取站点施工详细信息
	applyStationDevice:'api/company/station/applyStationDevice',//申请站点设备
	deliveryStationDevice:'api/company/station/deliveryStationDevice',//设备出库
	cancelStationDeviceShip:'api/company/station/cancelStationDeviceShip',//取消设备出库
	confirmDeviceReceived:'api/company/station/confirmDeviceReceived',//确认收货
	submitBuildInfo:'api/company/station/submitBuildInfo',//提交施工信息
	authBuildInfo:'api/company/station/authBuildInfo',//验收站点
	getStationTimeLine:'api/company/station/getStationTimeLine',//获取站点操作历史
	getStationList:'api/company/station/getStationList',//获取站点列表
	getStationTagList:'api/company/tag/list',//获取站点标签列表
	stationTagAdd:'api/company/tag/add',//添加站点标签
	updateSmokeCharge:'api/company/station/updateSmokeCharge',//设置站点发生烟雾报警时是否自动断电
	getAnxinTag:'api/user/anxin/getAnxinTag',//查询站点是否默认勾选安心充
	setAnxinTag:'api/user/anxin/setAnxinTag',//设置站点是否默认勾选安心充
	updateChargeLimit:'api/company/station/updateChargeLimit',//设置站点充电时是否检测支付了门禁费用
    
    
    //员工管理
    getCompanyUser:'api/company/rbac/getCompanyUser',//员工列表 post
    getCompanyUserDetail:'api/company/rbac/getCompanyUserDetail',//获取员工详情 get
    resetUserPassword:'api/company/rbac/resetUserPassword',//设置用户密码 get
    setCompanyUserValid:'api/company/rbac/setCompanyUserValid',//设置账户启用禁用 get
    delCompanyUser:'api/company/rbac/delCompanyUser',//删除员工 get
    getWebModuleRole:'api/company/rbac/getWebModuleRole',//获取web端权限模块列表 get
    getWxAppModuleRole:'api/company/rbac/getWxAppModuleRole',//获取小程序端权限模块列表 get
    getDevopsAppModuleRole:'api/company/rbac/getWxDevOpsAppModuleRole',//获取运维端权限模块列表 get
    saveRbacUser:'api/company/rbac/saveRbacUser',//保存员工信息 post
    getWebModuleUserRole:'api/company/rbac/getWebModuleUserRole',//获取用户的Web端模块权限列表 get
    getWxAppModuleUserRole:'api/company/rbac/getWxAppModuleUserRole',//获取用户的小程序端模块权限列表 get
    getDevopsAppModuleUserRole:'api/company/rbac/getWxDevOpsAppModuleUserRole',//获取用户的运维端模块权限列表 get
	getDepList:'api/company/rbac/getDepList',//获取部门列表 get
    getMenuByCompanyId:'api/company/rbac/getMenuByCompanyId',//获取菜单权限模块列表（全部）
    addRole:'api/company/rbac/addRole',//添加角色
    getRolePage:'api/company/rbac/getRoles',//角色列表
    getRolesList:'api/company/rbac/getRoleByCompanyId',//角色列表(不分页)
    getRoleDetailById:'api/company/rbac/getRoleDetailById',//角色详情
    

    // 月卡套餐管理
    getUserMonthcardPage:'api/user/monthCard/back/userMonthcardPage',//获取用户月卡套餐列表
    monthcardMove:'api/user/monthCard/back/move',//月卡套餐列表排序移动
    deleteMonthcard:'api/user/monthCard/back/delete',//删除月卡套餐
    getMonthcardPage:'api/user/monthCard/back/cardPage',//获取月卡套餐列表
    addUserMonthcard:'api/user/monthCard/back/addUserMonthcard',//添加用户月卡套餐
    addMonthCard:'api/user/monthCard/back/addMonthCard',//添加月卡套餐
    monthCardDdetails:'api/user/monthCard/back/monthCardDdetails',//月卡套餐详情
	bikeList:'api/user/monthCard/back/bikeList',//停车类型
    batterybList: 'api/user/monthCard/back/batterybList',//停车排量类型
    incomeDistriList: 'api/user/monthCard/back/incomeDistriList',//套餐配置时查询项目
    monthStationList: 'api/user/monthCard/back/stationList',//套餐配置时查询站点
    stopMonthcard: 'api/user/monthCard/back/stopMonthcard',//暂停月卡
    unbindMonthcard: 'api/user/monthCard/back/unbindMonthcard',//解绑月卡
    unbindCard: 'api/user/monthCard/back/unbindCard',//解绑实体卡
    bindCard: 'api/user/monthCard/back/bindCard',//绑定实体卡
    addUserMonthCardTime: 'api/user/monthCard/back/addUserMonthCardTime',//套餐配置时查询项目
    addCardGroup: 'api/user/monthCard/addCardGroup',//添加卡组
    getCardGroupList: 'api/user/monthCard/getCardGroupList',//获取卡组列表
    userMonthcardOperate: 'api/user/monthCard/userMonthcardOperate',//获取用户月卡操作记录
    bikeList: 'api/user/monthCard/back/bikeList',//停车类型
	batterybList: 'api/user/monthCard/back/batterybList',//停车排量类型
	monthCardTemplate: 'api/user/monthCard/getMonthCardTemplate',//月卡套餐模板
    monthCardTemplateList: 'api/user/monthCard/getMonthCardTemplateGroupId',//获取套餐模版列表
	bindRfCard: 'api/user/monthCard/back/bindRfCard',//套餐绑定rf卡
	unbindRfCard: 'api/user/monthCard/back/unbindRfCard',//套餐解绑rf卡
    getMonthcardCanFace: 'api/user/monthCard/getMonthcardCan',//获取套餐卡是否可以人脸或RFID
    getUserRfcardList: 'api/user/rfcard/front/getUserRfcardList',//获取用户RFID列表
    addMonthCardTemplateGroup: 'api/user/monthCard/addMonthCardTemplateGroup',//模板組添加
    addMonthCardTemplate: 'api/user/monthCard/addMonthCardTemplate',//模板組模板添加
    monthCardTemplateGroupSort: 'api/user/monthCard/monthCardTemplateGroupSort',//模板組模板排序
    monthCardGroupDetails: 'api/user/monthCard/back/monthCardGroupDetails',//月卡套餐组详情
    deleteMonthCardTemplate: 'api/user/monthCard/deleteMonthCardTemplate',//月卡套餐组详情

    // 活动配置
	addNewActivity: 'api/order/activity/addNewActivity',//创建活动
	listActivity: 'api/order/activity/listActivity',//活动列表
	activityInfo: 'api/order/activity/listActivityDetail',//活动详情
	updateActivity: 'api/order/activity/updateActivity',//编辑活动
	updateActivityStatus: 'api/order/activity/changeActivityStatus',//编辑活动状态
	activityUserRecordPage: 'api/order/activity/activityUserRecordPage',//用户领取活动记录
	getActivityGoodsInfo: 'api/order/activity/getActivityGoodsInfo',//活动关联套餐
    

    // 导出
    exportFileApi:'api/user/export/add',// 文件导出公共接口
    exportFilePage:'api/user/export/page',// 文件导出公共接口
    
    

	//菜单
	getUserMenu:'api/company/rbac/getUserMenu',//获取用户菜单 get

    //字典
    getDictionary:'api/user/dic/getDictionary',//字典
    getCodeByName:'api/company/dict/getCodeByName',//根据省市区名称换取省市区code

    //设备软件上传
    updateDevSoftware: 'api/update/update/updateFile',
    updateDevSoftwarePath: 'api/chg/v1/iot/down/updateDevSoftwarePath',

    // 数据大屏
    historyChargeMap:'api/order/dataCenter/historyChargeMap',//充电热力图（地图）
    stationMap:'api/order/dataCenter/stationMap',//充电桩分布图（地图）
    bikeTrends:'api/order/dataCenter/bikeTrends',//车辆趋势图
    amountTrends:'api/order/dataCenter/amountTrends',//销售额
    totalTransactionAmount:'api/order/dataCenter/totalTransactionAmount',//每月交易额
    todayDataTotal:'api/order/dataCenter/todayDataTotal',//今日数据
    historyCountTotal:'api/order/dataCenter/historyCountTotal',//统计数据

    // 财务
    reconciliationList:'api/income/monthcard/reconciliation/list',//套餐账单列表
    reconciliationDetail:'api/income/monthcard/reconciliation/detail',//套餐账单列表详情
    turnoverList:'api/income/turnover/list',//收支流水列表
    backCompanyIncomePage:'api/income/back/income/backCompanyIncomePage',//收益账单表
    backIncomePaymentDays:'api/income/back/income/backIncomePaymentDays',//账期管理表
    backOrderIncomePage:'api/income/back/income/backOrderIncomePage',//收益账单详情表
    addPaymentDays:'api/income/back/income/addPaymentDays',//添加账期管理
    backDoBalanceDays:'api/income/back/income/backDoBalanceDays',//账期管理-平账
    backDoIncomeDays:'api/income/back/income/backDoIncomeDays',//账期管理-入账
    getStartDateIncome:'api/income/back/income/getStartDateIncome',//账期管理-获取项目账期开始时间
    showPaymentCountDays:'api/income/back/income/showPaymentCountDays',//账期管理-账期明细预览统计
    showPaymentDays:'api/income/back/income/showPaymentDays',//账期管理-账期明细预览
    backDeletePaymentDays:'api/income/back/income/backDeletePaymentDays',//账期管理-账期删除
    backUpdatePaymentDays:'api/income/back/income/backUpdatePaymentDays',//账期管理-账期修改
    companyWithdrawPage:'api/income/withdraw/getCompanyWithdraw',//商户对账
    companyWithdrawDetails:'api/income/withdraw/getCompanyWithdrawFlow',//商户对账详情
    getFinanceWallet:'api/company/finance/getFinanceWallet',//商户金融分期列表
    getPurchaseRepaymentPlan:'api/company/finance/getPurchaseRepaymentPlan',//商户金融分期还款详情列表
    getPurchaseRepaymentRecord:'api/company/finance/getPurchaseRepaymentRecord',//商户金融分期还款详情设备明细列表
    addInvoice:'api/user/invoice/addInvoice',//发票助手添加发票
	invoicePage:'api/user/invoice/page',//发票助手发票列表
	delInvoice:'api/user/invoice/delInvoice',//发票助手删除发票
    updateInvoice:'api/user/invoice/updateInvoice',//发票助手编辑发票

    // 财务扎帐
    reconciliationCompanyPage:'api/stat/reconciliation/companyPage',//商户扎帐统计
    reconciliationOrderPage:'api/stat/reconciliation/orderPage',//商户扎帐详情
    getRecStatus:'api/stat/reconciliation/getRecStatus',//获取商户当日扎帐状态
    recCompanyByTest:'api/stat/reconciliation/recCompanyByTest',//手动测试扎帐

    companyMerchantFilter:'api/stat/board/companyMerchantList',//商户扎帐商户选择下拉数据
    companyPayChannelList:'api/stat/board/companyPayChannelList',//商户扎帐详情 表A
    companyTripartite:'api/stat/board/companyTripartite',//商户扎帐详情 表D
    moneyBoard:'api/stat/board/moneyBoard',//商户扎帐详情 表B
    orderBoard:'api/stat/board/orderBoard',//商户扎帐详情 表C
    getCompanyMerchantId:'api/stat/board/getCompanyMerchantId',//商户id查询
    formulaList:'api/stat/board/formulaList',//财务扎帐公式表
    
    // 预警
    warningAddUserGroup:'api/stat/alert/addUserGroup',//添加修改预警人员群组
    warningEdit:'api/stat/alert/alertAddUpdate',//预警编辑
    warningDetails:'api/stat/alert/alertDetail',//预警详情
    warningManageList:'api/stat/alert/alertList',//预警管理列表
    checkUser:'api/stat/alert/checkUser',//检验预警人员手机号
    warningUserGroup:'api/stat/alert/getGroup',//预警人员群组列表
    warningUserGroupPage:'api/stat/alert/getGroupList',//预警人员群组列表
    getWarningGroupUser:'api/stat/alert/getGroupUser',//查询群组用户
    getWarningGroupDetails:'api/stat/alert/getGroupDetail',//查询群组用户
    getWarningRecordPage:'api/stat/alert/back/record/page',//查询预警记录列表
    warningRecordAddMark:'api/stat/alert/front/record/message/add',//预警添加备注
    warningRecordMarkList:'api/stat/alert/front/record/message/list',//查询预警备注
    warningRecordDeal:'api/stat/alert/front/record/deal',//预警本人处理
    alertChargeDown:'api/stat/alert/front/record/alertChargeDown',//烟雾预警时，手动关电
    alertSpray:'api/stat/alert/front/record/alertSpray',//烟雾预警时，手动开启喷淋

    // 电费管理
    electricEdit:'api/stat/electric/addUpdate',//电费项目添加
    electricDel:'api/stat/electric/del',//电费项目删除
    electricInfo:'api/stat/electric/detail',//电费项目详情
    electricFilter:'api/stat/electric/filter',//电费项目下拉筛选
    electricHis:'api/stat/electric/historyList',//电费项目修改历史列表
    electricPage:'api/stat/electric/list',//电费项目列表

    propertyCompanyEdit:'api/stat/propertyCompany/addUpdate',//编辑添加物业
    propertyCompanyfilter:'api/stat/propertyCompany/filter',//物业下拉筛选
    propertyCompanyPage:'api/stat/propertyCompany/list',//物业列表
    propertyCompanyInfo:'api/stat/propertyCompany/detail',//物业详情

    ammeterAdd:'api/stat/ammeter/add',//添加电表
    ammeterFilter:'api/stat/ammeter/filter',//电表下拉筛选

    electricBillPage:'api/stat/bill/electric/project/page',//缴费项目账单
    electricBillAdd:'api/stat/bill/electric/bill/add',//添加缴费账单
    electricBillRecordAmmeter:'api/stat/bill/electric/bill/ammeter/record',//缴费项目账单详情--电表维度
    electricBillRecordBill:'api/stat/bill/electric/bill/project/record',//缴费项目账单--账单维度
    electricBillChart:'api/stat/bill/electric/bill/chart',//缴费趋势图
    electricBillInfo:'api/stat/bill/electric/bill/get',//电费账单基础信息
    electricBillDelete:'api/stat/bill/electric/bill/delete',//电费账单删除
    
    electricStationFilter:'api/stat/electric/station/filter',//站点下拉选择

    addUpdateCustomerPhone:'api/company/customer/addUpdateCustomerPhone',//添加自定义客服
    queryCustomerPhone:'api/company/customer/queryCustomerPhoneConfig',//自定义客服电话查询

    // 客诉
    complaintPage:'api/order/complaint/page',//客诉列表
    complaintDetail:'api/order/complaint/detail',//客诉详情
    complaintCount:'api/order/complaint/complaintCount',//客诉统计
    complaintReminder:'api/order/complaint/complaintReminder',//客诉超时提醒查询
    complaintHistorys:'api/pay/complaint/historys',//客诉历史处理记录
    complaintApply:'api/order/complaint/reply',//客诉回复
    complaintUpdateRefund:'api/order/complaint/updateRefund',//客诉驳回/发起退款
    complaintUploadImage:'api/pay/complaint/uploadImage',//客诉回复上传图片
    queryWxImage:'api/pay/complaint/queryImage',//获取微信图片资源
    complaintComplete:'api/pay/complaint/complete',//处理完成
    addComplaintLiaison:'api/company/customer/addComplaintLiaison',//添加商户客诉通知人
    complaintReminder:'api/stat/alert/complaintReminder',//查询商户客诉通知人

    // 优惠券
    couponPage:'api/user/coupon/couponPage',//优惠券列表
    couponAddUpdate:'api/user/coupon/addUpdate',//优惠券添加编辑
    couponUsePage:'api/user/coupon/couponUsePage',//优惠券使用人列表
    getCouponDetail:'api/user/coupon/getCouponDetail',//优惠券详情

    // 商品
    getGoodsList:'api/order/goods/getGoodsList',//商品列表

    // 任务
    taskReviewRecordPage:'api/stat/taskReview/taskReviewRecordPage',//查询任务审核列表
    getCommunityQuestionDetail:'api/stat/taskReview/getCommunityQuestionDetail',//查询任务详情
    taskReview:'api/stat/taskReview/taskReview',//任务审核
    communityQuestionGeo: 'api/stat/taskReview/communityQuestionGeo',//获取小区信息登记者附近已登记信息

    // 工程
    engineerEdit: 'api/company/construction/addUpdate',//工程合同编辑
    engineerInfo: 'api/company/construction/detail',//工程合同详情
    engineerOperateList: 'api/company/construction/list',//工程合同操作日志列表
    engineerList: 'api/company/construction/page',//工程合同列表
    engineerIncomePage: 'api/income/construction/back/incomePage',//工程收益列表

    // 工单
    addWorkOrder: 'api/company/workOrder/addWorkOrder',//添加工单
    getQuestionAnswerItem: 'api/company/workOrder/getQuestionAnswerItem',//工单巡检详情（当前工单关联了巡检时用）
    getWorkOrderContent: 'api/company/workOrder/getWorkOrderContent',//工单描述详情
    getWorkOrderDetail: 'api/company/workOrder/getWorkOrderDetail',//工单详情
    getWorkOrderOperateHistory: 'api/company/workOrder/getWorkOrderOperateHistory',//工单进度
    operateWorkOrder: 'api/company/workOrder/operateWorkOrder',//工单操作
    workOrderPage: 'api/company/workOrder/page',//工单列表
    getWorkOrderErrorType: 'api/company/workOrder/getWorkOrderErrorType',//工单类型
    workOrderPageCount: 'api/company/workOrder/pageCount',//工单列表统计
    commitWorkOrder: 'api/company/workOrder/commitWorkOrder',//工单处理
    getExecutor: 'api/company/rbac/back/userList',//查询执行人列表

    //巡检
    inspectionEdit: 'api/company/patrol/back/add',//创建巡检
    inspectionAnswer: 'api/company/patrol/back/answer',//巡检提交
    inspectionChangeSolveUser: 'api/company/patrol/back/changeSolveUser',//指派人
    inspectionCheckDevice: 'api/company/patrol/back/checkDevice',//扫码检查设备
    inspectionDetails: 'api/company/patrol/back/details',//巡检详情
    inspectionLog: 'api/company/patrol/back/log',//巡检单日志
    inspectionPage: 'api/company/patrol/back/page',//巡检列表
    inspectionPause: 'api/company/patrol/back/pause',//巡检挂起
    inspectionImportance: 'api/company/patrol/back/importance',//巡检修改优先级
    inspectionStart: 'api/company/patrol/back/start',//执行巡检
    inspectionStatus: 'api/company/patrol/back/status',//巡检单状态统计
    inspectionTemplateList: 'api/company/patrol/back/templateList',//巡检模板列表
    inspectionWorkList: 'api/company/patrol/back/workList',//站点存在的工单列表
    inspectionQuestionsList: 'api/company/question/question/get',//巡检关联问卷
    inspectionHasPatrolt: 'api/company/patrol/back/hasPatrol',//n天内某站点有无巡检
    inspectionStatus: 'api/company/patrol/back/status',//巡检单状态统计
    inspectionCheckStation: 'api/company/patrol/back/checkStation',//创建巡检时，check站点是否合规
    inspectionOfflineDevice: 'api/company/patrol/back/offlineDevice',//巡检时，查看是否有离线设备

    // 运维看板（巡检，工单）
    patrolTotal: 'api/company/patrolWork/patrolTotal',//巡检看板总览
    patrolBarChart: 'api/company/patrolWork/patrolBarChart',//巡检看板柱状图
    patrolFrequency: 'api/company/patrolWork/patrolFrequency',//巡检评率
    errorRecord: 'api/company/patrolWork/errorRecord',//巡检异常记录
    patrolRecord: 'api/company/patrolWork/patrolRecord',//巡检记录
    getWorkOrderTrend: 'api/company/workOrder/getWorkOrderTrend',//工单趋势
    getWorkOrderNowInfo: 'api/company/workOrder/getWorkOrderNowInfo',//工单统计
    getRbacUserEfficiency: 'api/company/workOrder/getRbacUserEfficiency',//员工效率
    getWorkOrderErrorStat: 'api/company/workOrder/getWorkOrderErrorStat',//高频异常问题统计
    getWorkOrderRbacUserInfo: 'api/company/workOrder/getWorkOrderRbacUserInfo',//员工处理中的工单
    getWorkOrderRbacUserInfoDetail: 'api/company/workOrder/getWorkOrderRbacUserInfoDetail',//员工处理中的工单详情
    getTotalEfficiency: 'api/company/workOrder/getTotalEfficiency',//员工效率统计
    getWorkOrderTypeStat: 'api/company/workOrder/getWorkOrderTypeStat',//员工效率统计
    getWorkOrderRbacUserEfficiency: 'api/company/workOrder/getWorkOrderRbacUserEfficiency',//员工效率统计
    getWorkOrderRbacUserWorkTrend: 'api/company/workOrder/getWorkOrderRbacUserWorkTrend',//员工效率统计
    
}
