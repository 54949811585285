<script>
export default {
    name: "MenuItem",
    functional: true,
    props: {
        icon: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        colorClass: {
            type: String,
            default: "a-c-master",
        },
    },
    render(h, context) {
        const { icon, title, colorClass } = context.props;
        const vnodes = [];

        if (icon) {
            // vnodes.push(<svg-icon icon-class={icon}/>)
            vnodes.push(
                <img src={icon} class="a-wh-16" style="margin-right:8px" />
            );
        }

        if (title) {
            vnodes.push(<span slot="title">{title}</span>);
        }
        return vnodes;
    },
};
</script>
