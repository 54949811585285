<template>
	<div class="a-flex-rfsc a-ptb-12" style="margin-left: 20px;">
        <div class="s-search-label a-flex-rfec" style="white-space: nowrap;margin-right: 20px;" v-show="label">
			<div>{{label}}</div>
		</div>
		<el-input class="a-flex-1" :placeholder="placeholder" :maxlength="maxlength?maxlength:null"
			:value="value" @input="handlerChange" :style="'width:'+width+'px'">
            <el-select :value="selectValue" @change="selectChange" slot="prepend" placeholder="请选择" :style="'width:'+selectWidth+'px'">
                <el-option 
                    v-for="(item,index) in selectOptions"
                    :key="index"
                    :label="item.label" 
                    :value="item.value"></el-option>
            </el-select>
		</el-input>
	</div>

</template>

<script>
export default {
	props: {
        label: {
            default: ''
        },
		value: {
			required: true,
			default: ''
		},
        selectValue:{
            default: ''
        },
		placeholder: {
			type: String,
			default: '请输入'
		},
		/**
		 * 是否为较长的输入框
		 */
		large: {
			type: Boolean,
			required: false,
			default: false
		},
        maxlength: {
            type: Number,
            default: 0
        },
        selectOptions: {
            require: true
        },
        width:{
            default: 320
        },
        selectWidth: {
            default: 100
        }
	},
	data() {
		return { }
	},
	methods: {
		handlerChange(e) {
            e = e.replace(/\s*/g,"")
			this.$emit('update:value',e)
		},
        selectChange (e) {
            this.$emit('update:selectValue',e)
        }
	}
}
</script>

<style lang="scss" scoped>

</style>
