<template>
	<div class="a-flex-rfsc a-ptb-12" >
        <div class="a-flex-rfsfs changeTime-tabs a-fs-14">
            <span 
                @click="changeTimeType(index)"
                :class="{ 'active': timeType == index }"
                v-for="(item, index) in timeTypeDic" 
                :key="item">
                {{ item }}
            </span>
        </div>
		<el-date-picker 
            :value-format="valueFormat" 
            class="date-range" 
            :class="large?'date-range-large':'date-range'" 
            v-model="value1" 
            :type="type"
            :clearable='clearable'
			range-separator="-" 
            :start-placeholder="startPlaceholder" 
            :end-placeholder="endPlaceholder" 
            :default-time="['00:00:00','23:59:59']"
            :key="timeType"
            :pickerOptions="pickerOptions"
            @change="handlerOnPick">
		</el-date-picker>
	</div>
</template>

<script>
	export default {
		props: {
            valueFormat:{
                type: String,
                default: 'yyyy-MM-dd'
            },
			label: {
				type: String
			},
			minDate: {
				required: false,
			},
			maxDate: {
				required: false,
			},
            clearable: {
                type: Boolean,
                default: true
            },
			startPlaceholder: {
				required: false,
				type: String,
				default: '开始日期'
			},
			endPlaceholder: {
				required: false,
				type: String,
				default: '结束日期'
			},
			/**
			 * 是否必填
			 */
			notnull: {
				type: Boolean,
				default: false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large: {
				type: Boolean,
				required: false,
				default: false
			},
            pickerOptions: {
				require: false
			},
            timeTypeDic: {
                default: ()=>{
                    return ['日', '月']
                },
            },
            timeType: {
                default: 0
            }
		},
		data() {
			return {
				value1: [],
                type: 'daterange'
			}
		},
        computed: {
            dateVal () {
                return this.minDate + this.maxDate
            }
        },
        watch: {
            dateVal:{
				immediate: true,
                handler (val) {
                    if(!val){
                        this.value1 = []
                    }else{
						this.$set(this.value1,0,this.minDate)
						this.$set(this.value1,1,this.maxDate)
					}
                }
            }
        },
		mounted() {

		},
		methods: {
			handlerOnPick(e) {
                let startDate = e?e[0]:''
                let endDate = e?e[1]:''
                if(this.timeType == 1){
                    startDate = startDate?this.$getDay.getSomeMonthDays(startDate,'YYYY-MM-DD HH:mm:ss').starttime:''
                    endDate = endDate?this.$getDay.getSomeMonthDays(endDate,'YYYY-MM-DD HH:mm:ss').endtime:''
                }
				this.$emit('update:minDate', startDate)
				this.$emit('update:maxDate', endDate)
                this.$emit('change')
			},
            changeTimeType (index) {
                let typeDic = ['daterange','monthrange','yearrange']
                let startDate = this.minDate?this.minDate:''
                let endDate = this.maxDate?this.maxDate:''

                this.type = typeDic[index]
                if(index == 0){
                    startDate = this.minDate?this.$getDay.getSomeMonthDays(this.minDate,'YYYY-MM-DD HH:mm:ss').starttime:''
                    endDate = this.minDate?this.$getDay.getSomeMonthDays(this.minDate,'YYYY-MM-DD HH:mm:ss').endtime:''
                }else if(index == 1){
                    startDate = this.minDate?this.$getDay.getSomeMonthDays(this.minDate,'YYYY-MM-DD HH:mm:ss').starttime:''
                    endDate = this.maxDate?this.$getDay.getSomeMonthDays(this.maxDate,'YYYY-MM-DD HH:mm:ss').endtime:''
                }

                this.$emit('update:timeType', index);
				this.$emit('update:minDate', startDate)
				this.$emit('update:maxDate', endDate)
                this.$emit('change')
            }
		}
	}
</script>

<style lang="scss" scoped>
    .changeTime-tabs {
        margin-right: 12px;
        span {
            border: 1px #DADDE0 solid;
            border-left: 1px #fff solid;
            background: #fff;
            height: 32px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            width: 64px;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: #f9f9f9;
            }

            &:nth-of-type(1) {
                border-radius: 4px 0 0 4px;
                border-left: 1px #DADDE0 solid;
            }

            &:nth-last-of-type(1) {
                border-radius: 0 4px 4px 0;
            }

            &.active {
                border: 1px solid #007AFF;
                color: #007AFF;
            }
        }
    }
	.date-range {
		width: 240px;
	}
	
	.date-range .el-range-input {
		width: 90px;
	}
	
	.date-range-large {
		width: 376px;
	}
	
	.date-range-large .el-range-input {
		width: 108px;
	}

</style>
