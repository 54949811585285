function forMatNum(num){
	return num<10?'0'+num:num+'';
}

function initDays(year,month){
	let totalDays=new Date(year,month,0).getDate();
	let dates=[];
	for(let d=1;d<=totalDays;d++){
		dates.push(forMatNum(d));
	};
	return dates;
}
function initPicker(start,end,mode="date",step) {
	let aToday=new Date();
	let tYear=aToday.getFullYear().toString();
	let tMonth=forMatNum(aToday.getMonth()+1).toString();
	let tDay=forMatNum(aToday.getDate()).toString();
	let tHours=forMatNum(aToday.getHours()).toString();
	let tMinutes=forMatNum(aToday.getMinutes()).toString();
	let tSeconds=forMatNum(aToday.getSeconds()).toString();
	let initstartDate=new Date(start);
	let endDate=new Date(end);
	if(start>end){
		initstartDate=new Date(end);
		endDate=new Date(start);
	};
	let startYear=initstartDate.getFullYear();
	let startMonth=initstartDate.getMonth();
	let endYear=endDate.getFullYear();
	let years=[],months=[],days=[],hours=[],minutes=[],seconds=[],defaultVal=[];
	let totalDays=new Date(startYear,startMonth,0).getDate();
	for(let s=startYear;s<=endYear;s++){
		years.push(s+'');
	};
	for(let m=1;m<=12;m++){
		months.push(forMatNum(m));
	};
	for(let d=1;d<=totalDays;d++){
		days.push(forMatNum(d));
	}
	for(let h=0;h<24;h++){
		hours.push(forMatNum(h));
	}
	for(let m=0;m<60;m+=step*1){
		minutes.push(forMatNum(m));
	}
	for(let s=0;s<60;s++){
		seconds.push(forMatNum(s));
	}
	switch(mode){
		case "date":
			defaultVal=[years.indexOf(tYear),months.indexOf(tMonth),days.indexOf(tDay)];
			return {years,months,days,defaultVal}
			break;
		case "yearMonth":
			defaultVal=[years.indexOf(tYear),months.indexOf(tMonth)];
			return {years,months,defaultVal}
			break;
		case "dateTime":
			defaultVal=[years.indexOf(tYear),months.indexOf(tMonth),days.indexOf(tDay),hours.indexOf(tHours),minutes.indexOf(tMinutes),seconds.indexOf(tSeconds)];
			return {years,months,days,hours,minutes,seconds,defaultVal}
			break;
		case "time":
			defaultVal=[hours.indexOf(tHours),minutes.indexOf(tMinutes),seconds.indexOf(tSeconds)];
			return {hours,minutes,seconds,defaultVal}
			break;			
	}
}

function copyData (target) {
	//先判断类型，再拷贝
	function checkType(val) {
		return Object.prototype.toString.call(val).slice(8, -1);
	}
	let res, type = checkType(target);
	if (type === 'Object') {
		res = {};
	} else if (type === 'Array') {
		res = [];
	} else {
		return target;
	}
	for (let i in target) { //数组和对象 for in 循环
		let value = target[i];
		if (value && (checkType(value) === 'Object' || checkType(value) === 'Array')) { //嵌套
			res[i] = this.copyData(value);
		} else { //基本数据或者函数
			res[i] = value;
		}
	}
	return res;
}

function formatDate(date, fmt) {
    let o = {
		"Y+": date.getFullYear(),//年份
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

function easyformatDate(data){
	let nDate=data?new Date(Number(data)):new Date()

		const year = nDate.getFullYear();
		let month = nDate.getMonth()+1;
		let day = nDate.getDate();
		const hours=nDate.getHours();
		const minuts=nDate.getMinutes();
		const seconds=nDate.getSeconds();
		//return year +'/'+ month +'/'+ day+' '+hours+ ':'+minuts+':'+seconds
		if(month<10){
			month='0'+month
		}
	day=day<10?'0'+day:day

		return year+'-'+month+'-'+day
}

function allformatDate(data){
	let nDate=data?new Date(Number(data)):new Date()

		const year = nDate.getFullYear();
		let month = nDate.getMonth()+1;
		let day = nDate.getDate();
		let hours=nDate.getHours();
		let minuts=nDate.getMinutes();
		let seconds=nDate.getSeconds();
		//return year +'/'+ month +'/'+ day+' '+hours+ ':'+minuts+':'+seconds
		if(month<10){
			month='0'+month
		}
	day=day<10?'0'+day:day
	hours=hours<10?'0'+hours:hours
	minuts=minuts<10?'0'+minuts:minuts
	seconds=seconds<10?'0'+seconds:seconds

		return year+'.'+month+'.'+day+' '+hours+ ':'+minuts+':'+seconds
}

function formatLocation(longitude, latitude) {
	if (typeof longitude === 'string' && typeof latitude === 'string') {
		longitude = parseFloat(longitude)
		latitude = parseFloat(latitude)
	}

	longitude = longitude.toFixed(2)
	latitude = latitude.toFixed(2)

	return {
		longitude: longitude.toString().split('.'),
		latitude: latitude.toString().split('.')
	}
}

/**
 * 格式化数字,以万千为单位展示,保留1位小数点
 * @param {Object} num
 */
function formatNumCn(num){
	if(num == undefined){
		return 0;
	}
	if(num>1000 && num<10000){
		return Math.round(num/1000,1)+'千'
	}
	if(num>10000){
		return Math.round(num/10000,1)+'万'
	}
	return num;
}

function rpad(str,padstr,length){
	str+=''
	while(str.length<length){
		str = str+''+padstr
	}
	return str;
}

function lpad(str,padstr,length){
	str+=''
	while(str.length<length){
		str = padstr+''+str
	}
	return str;
}

//格式化金额
function formatMoney(amount) {
		
	if(amount== NaN){
		return '0'
	}
	if (amount !== undefined) {
		if (amount % 100 === 0) {
			return parseInt(amount / 100)
		} else {
			return (amount / 100).toFixed(2)
		}
	}else{
		return '0'
	}
	
}

//添加金额千分位分隔符

function numFormat(a){
	if(!a){
		return '0.00';
	}
    var num = Number(a);
    if(!num){//等于0
        return num+'.00';
    }else{//不等于0
        num = Math.round((num)*100)/10000;
        num = num.toFixed(2);
        num+='';//转成字符串
        var reg=num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
       // console.log(num.indexOf('.')>-1)
        return num.replace(reg, '$1,')//千分位格式化
    }
}

function numFormatNotValue(a){
	if(!a && a !== 0 && a !== '0'){
		return '-';
	}else if(a === 0 || a === '0'){
        return '￥0.00';
    }
    var num = Number(a);
    if(!num){//等于0
        return num+'.00';
    }else{//不等于0
        num = Math.round((num)*100)/10000;
        num = num.toFixed(2);
        num ='￥' + num;//转成字符串
        var reg=num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
       // console.log(num.indexOf('.')>-1)
        return num.replace(reg, '$1,')//千分位格式化
    }
}

//添加金额千分位分隔符

function numFormat4(a){
	if(!a){
		return '0.00';
	}
    var num = Number(a);
    if(!num){//等于0
        return num+'.00';
    }else{//不等于0
        num = (num/100).toFixed(4);
        num+='';//转成字符串
        var reg=num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
       // console.log(num.indexOf('.')>-1)
        return num.replace(reg, '$1,')//千分位格式化
    }
}

function numFormat5(a){
	if(!a){
		return '0.00';
	}
    var num = Number(a);
    if(!num){//等于0
        return num+'.00';
    }else{//不等于0
        num = (num/100).toFixed(5);
        num+='';//转成字符串
        var reg=num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
       // console.log(num.indexOf('.')>-1)
        return num.replace(reg, '$1,')//千分位格式化
    }
}

function numFormatExcept10To5(a){
	if(!a){
		return '0.00';
	}
    var num = Number(a);
    if(!num){//等于0
        return num+'.00';
    }else{//不等于0
        num = (num/10).toFixed(5);
        num+='';//转成字符串
        var reg=num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
       // console.log(num.indexOf('.')>-1)
        return num.replace(reg, '$1,')//千分位格式化
    }
}

function radioFormat(a){
	if(!a){
		return '0';
	}
    var num = Number(a);
    return (parseInt(num*10000)/100).toFixed(2)
}


/**
 * 转换年月日方法(暂时支持以下两种,如果需要其他格式,自己再拼接)
 * format :yyyy-mm-dd，yyyy/mm/dd
 * str : 时间戳
*/
function getDate(format, str) {
	let oDate = new Date(str)
	let oYear = oDate.getFullYear()
	let oMonth = oDate.getMonth() + 1
	let oDay = oDate.getDate()
	let oHour = oDate.getHours()
	let oMin = oDate.getMinutes()
	let oSec = oDate.getSeconds()

	if (format == 'yyyy-mm-dd') {
		return oYear + '-' + getzf(oMonth) + '-' + getzf(oDay);//最后拼接时间
	} else if (format == 'yyyy/mm/dd') {
		return oYear + '/' + getzf(oMonth) + '/' + getzf(oDay);//最后拼接时间
	}

};
//补0操作  
function getzf(num) {
	if (parseInt(num) < 10) {
		num = '0' + num;
	}
	return num;
}

function formatAddress(address){
	if(!address){
		return ''
	}
	return address.replace('北京市北京市','北京市').replace('上海市上海市','上海市').replace('天津市天津市','天津市').replace('重庆市重庆市','重庆市');
}

function download(url) {
	window.location.href = url
	// fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
	// 	const a = document.createElement('a')
	// 	a.href = URL.createObjectURL(blob)
	// 	//测试链接console.log(a.href)
	// 	a.download = fileName  // 下载文件的名字
	// 	document.body.appendChild(a)
	// 	a.click()
	// })
}
// 价格输入限制
function checkMoney (value) {
    var t = value.charAt(0); 
	value = value.replace(/[^\d\.]/g, '');
	//必须保证第一个为数字而不是.     
	value = value.replace(/^\./g,'');
	//保证只有出现一个.而没有多个.     
	value = value.replace(/\.{2,}/g,'.');
	//保证.只出现一次，而不能出现两次以上     
	value = value.replace('.','$#$').replace(/\./g,'').replace('$#$','.');
	//只能输入两位小数
	value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');

    if(t == '-'){
		value = '-'+value;
	}
	return value
}
// 价格输入限制
function checkMoney4 (value) {
	value = value.replace(/[^\d\.]/g, '');
	//必须保证第一个为数字而不是.     
	value = value.replace(/^\./g,'');
	//保证只有出现一个.而没有多个.     
	value = value.replace(/\.{2,}/g,'.');
	//保证.只出现一次，而不能出现两次以上     
	value = value.replace('.','$#$').replace(/\./g,'').replace('$#$','.');
	//只能输入两位小数
	value = value.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/,'$1$2.$3');
	return value
}
// 价格输入限制
function checkMoney5 (value) {
	value = value.replace(/[^\d\.]/g, '');
	//必须保证第一个为数字而不是.     
	value = value.replace(/^\./g,'');
	//保证只有出现一个.而没有多个.     
	value = value.replace(/\.{2,}/g,'.');
	//保证.只出现一次，而不能出现两次以上     
	value = value.replace('.','$#$').replace(/\./g,'').replace('$#$','.');
	//只能输入两位小数
	value = value.replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/,'$1$2.$3');
	return value
}
// 只能输入一位小数
function checkMoney1 (value) {
	value = value.replace(/[^\d\.]/g, '');
	//必须保证第一个为数字而不是.     
	value = value.replace(/^\./g,'');
	//保证只有出现一个.而没有多个.     
	value = value.replace(/\.{2,}/g,'.');
	//保证.只出现一次，而不能出现两次以上     
	value = value.replace('.','$#$').replace(/\./g,'').replace('$#$','.');
	//只能输入一位小数
	value = value.replace(/^(\-)*(\d+)\.(\d).*$/,'$1$2.$3');
	return value
}

function checkIntNum (value) {
	return value.replace(/^\.+|[^\d]+/g,'')
}
function checkInteger (num) {
	//得到第一个字符是否为负号
	var t = num.charAt(0); 
	num = num.replace(/^\.+|[^\d]+/g,'')
	//如果第一位是负号，则允许添加
	if(t == '-'){
		num = '-'+num;
	}
	return num;
}

// 秒 换算 小时分钟
function secondToHoursMinute (val) {
	if(val){
		let hour = parseInt(val / 60 / 60)
		let minute = parseInt((val - hour * 60 * 60) / 60)
		return (hour?hour+'H':'') + (minute?minute+'m':'')
	}else{
		return '0H0m'
	}
	
}
 
// 10进制转16进制 再反序后转回10进制
function ox16Reverse (val) {
    let numVal = Number(val)
    let arr = []
    numVal = numVal.toString(16)
    let fillIndex = 8 - numVal.length
    if(fillIndex > 0){
        let fillZero = new Array(fillIndex).fill(0).join('')
        numVal = fillZero + numVal
    }
    for (let index = 0; index < numVal.length; index = index + 2) {
        arr.push(numVal[index] + numVal[index+1])
    }
    return parseInt(arr.reverse().join('').toString(10), 16)
}

// 判断是否为数据
function isArray (arr) {
    return Array.isArray(arr)
}

/**
 * 分析每一列，找出所有【列】可合并（数据相同）的单元格
 * @param {Array} tableData 表数据
 * @param {Array} tableColumn 表字段/表头
 * @param {Array} mergeCols 指定合并哪些列（字段）
 * @returns
 */
function getMergeCells (tableData = [], tableColumn = [], mergeCols = []) {
    // const fields = tableColumn.map(v => v.prop)
    const fields = tableColumn
    const array = []

    if (!tableData.length || !tableColumn.length || !mergeCols.length) return

    // 倒叙遍历行（方便统计合并列单元格数至最上方，避免表格塌陷）
    for (let row = tableData.length - 1; row >= 0; row--) {
        array[row] = []
        for (let col = 0; col < fields.length; col++) {
            // 1.最后一行单元格不合并（初始无可对比数据）
            // 2.不在指定列（mergeCols）的单元格不合并
            // 3.空值不合并
            if (row === tableData.length - 1 || !mergeCols.includes(fields[col]) ) { //|| !tableData[row][fields[col]]
                array[row][col] = [1, 1]
                continue
            }

            // 5.合并相同数据的单元格
            if (tableData[row][fields[col]] === tableData[row + 1][fields[col]]) {
                const beforeCell = array[row + 1][col]
                array[row][col] = [1 + beforeCell[0], 1]
                beforeCell[0] = 0
                beforeCell[1] = 0
            } else {
                array[row][col] = [1, 1] // 否则不合并
            }
        }
    }
    // console.log(array, 'array')
    return array
}
  
function initDic (val, dicType) {
    let dicDatas = JSON.parse(window.sessionStorage.getItem('dic'))
	let dic = dicDatas.find(res=>{
		return res.type== dicType
	})
	let dic_list = dic?dic.dicList:[]
	if(dic_list && dic_list.length){
		let dic_val = dic_list.find(res=>{
			return res.code == val
		})
		return dic_val?dic_val.name:'-'
	}else{
		return '-'
	}
}

function hidePhoneNumber(phone) {
    if(phone) {
        return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    }else {
        return ''
    }
}

export default{
	initDays,
	initPicker,
	copyData,
	formatDate,
	easyformatDate,
	formatLocation,
	formatNumCn,
	rpad,
	lpad,
	formatMoney,
	getDate,
	numFormat,
    numFormat4,
    numFormat5,
    numFormatNotValue,
    numFormatExcept10To5,
    radioFormat,
	allformatDate,
	formatAddress,
	download,
	checkMoney,
	checkIntNum,
	checkInteger,
	secondToHoursMinute,
	checkMoney1,
	checkMoney4,
    checkMoney5,
    ox16Reverse,
    isArray,
    getMergeCells,
    initDic,
    hidePhoneNumber
}