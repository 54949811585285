<template>
    <div>
        <el-dialog
            title="导出文件命名"
            :visible.sync="showExportDialog"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            :show-close="false"
            :modal="true"
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="100px">
                <el-form-item label="文件名称" prop="fileName">
                    <el-input v-model="form.fileName" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="cancel">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapGetters, mapState, mapActions } from "vuex";
    export default {
        data() {
            return {
                rules:{
                    fileName: [{required: true, message:'请输入文件名称', trigger: 'blur'}],
                }
            };
        },
        watch:{
            showExportDialog (val) {
                if(!val){
                    this.setExportParams({
                        code: '',
                        fileName: '',
                        startTime: '',
                        endTime: '',
                        params: {}
                    })
                }
                if(this.$refs['form']){
                    this.$refs['form'].resetFields()
                }
            },
        },
        computed: {
            ...mapState({
                showExportDialog: (state) => state.exportFile.showExportDialog,
                form: (state) => state.exportFile.exportParams,
            }),
        },
        methods:{
            ...mapActions({
                setShowTips: "exportFile/setShowTips",
                setExportDialog: "exportFile/setExportDialog",
                setExportParams: "exportFile/setExportParams"
            }),
            cancel () {
                this.setExportDialog(false)
            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$Axios._post({
                            url: this.$Config.apiUrl.exportFileApi,
                            method: "post",
                            params: this.form
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.setShowTips()
                                this.setExportDialog(false)
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{

                        })
                        
                    }
                })
            }
        }
    }
</script>