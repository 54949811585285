<template>
	<div class="a-flex-rfsc a-ptb-12" >
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span>{{label}}</span>
		</div>
		<el-date-picker :editable="editable" :value-format="valueFormat" class="date-range a-ml-20" :class="large?'date-range-large':'date-range'" v-model="value1" type="daterange" :default-time="defaultTime"
			range-separator="-" :start-placeholder="startPlaceholder" :end-placeholder="endPlaceholder" :pickerOptions="pickerOptions" :clearable="clearable" @change="handlerOnPick">
		</el-date-picker>
	</div>
</template>

<script>
	export default {
		props: {
			label: {
				type: String
			},
			minDate: {
				required: false,
				type: String,
				validator: (param) => {
					if (param && !param.match(/\d{4}-[01]{1}\d{1}-[0,1,2,3]{1}\d{1}/)) {
						console.warn('[le-date-range]minDate参数不合法:请填写YYYY-MM-DD的日期格式')
						return false;
					} else {
						return true
					}
				},
		
			},
			maxDate: {
				required: false,
				type: String,
				validator: (param) => {
					if (param && !param.match(/\d{4}-[01]{1}\d{1}-[0,1,2,3]{1}\d{1}/)) {
						console.warn('[le-date-range]maxDate参数不合法:请填写YYYY-MM-DD的日期格式')
						return false;
					} else {
						return true
					}
				}
			},
			startPlaceholder: {
				required: false,
				type: String,
				default: '开始日期'
			},
			endPlaceholder: {
				required: false,
				type: String,
				default: '结束日期'
			},
			/**
			 * 是否必填
			 */
			notnull: {
				type: Boolean,
				default: false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large: {
				type: Boolean,
				required: false,
				default: false
			},
			clearable: {
				type: Boolean,
				default: true
			},
			pickerOptions: {
				require: false
			},
			valueFormat:{
				default: 'yyyy-MM-dd'
			},
            defaultTime:{
                default: ()=>{
                    return ['00:00:00','00:00:00']
                }
            },
            editable:{
                default: false
            }
		},
		data() {
			return {
				value1: [this.minDate,this.maxDate]
			}
		},
        computed: {
            dateVal () {
                return this.minDate + this.maxDate
            }
        },
        watch: {
            dateVal:{
                handler (val) {
                    if(!val){
                        this.value1 = []
                    }
                }
            }
        },
		mounted() {
			//this.value1=[this.minDate,this.maxDate]
		},
		methods: {
			handlerOnPick(e) {
				this.$emit('update:minDate', e?e[0]:'')
				this.$emit('update:maxDate', e?e[1]:'')
				this.$emit('change', e)
			}
		}
	}
</script>

<style scoped>
	.date-range {
		width: 240px;
	}
	
	.date-range .el-range-input {
		width: 90px;
	}
	
	.date-range-large {
		width: 376px;
	}
	
	.date-range-large .el-range-input {
		width: 108px;
	}

</style>
