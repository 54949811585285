// import Decimal from '../assets/js/decimal'
// const Decimal = require('../assets/js/decimal.js');
import { Decimal } from 'decimal.js'
export default {
    decimal_add (...args) { //加法
        let result = 0
        for (var i = 0; i < args.length; i++) {
            result = new Decimal(result).add(new Decimal(args[i])) 
        }
        return Number(result)
    },
    decimal_reduce (...args) { //减法
        let result = args[0]
        for (var i = 1; i < args.length; i++) {
            result = new Decimal(result).sub(new Decimal(args[i])) 
        }
        return Number(result)
    },
    decimal_ride (...args) {  //乘法
        let result = args[0]
        for (var i = 1; i < args.length; i++) {
            result = new Decimal(result).mul(new Decimal(args[i])) 
        }
        return Number(result)
    },
    decimal_except (...args) { // 除法
        let result = args[0]
        for (var i = 1; i < args.length; i++) {
            result = new Decimal(result).div(new Decimal(args[i])) 
        }
        return Number(result)
    },
}