import {
	_post,
	_get
} from "@/axios/func";
import Config from "@/config";
const state = {
	menu: [
		// {
		// 	icon: require('../../assets/icon/menu-home.png'),
		// 	iconActive: require('../../assets/icon/menu-home-active.png'),
		// 	name: '首页',
		// 	path: '/home',
		// 	children: [
		// 		{
		// 			name: '首页',
		// 			path: '/home/index'
		// 		}
		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-order.png'),
		// 	iconActive: require('../../assets/icon/menu-order-active.png'),
		// 	name: '订单管理',
		// 	path: '/order',
		// 	children: [
		// 		{
		// 			name: '订单列表',
		// 			path: '/order/order-list'
		// 		}
		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-station.png'),
		// 	iconActive: require('../../assets/icon/menu-station-active.png'),
		// 	name: '站点管理',
		// 	path: '/station',
		// 	children: [
		// 		{
		// 			name: '站点列表',
		// 			path: '/station/station-list'
		// 		},
		// 		{
		// 			name: '新建站点',
		// 			path: '/station/station-add'
		// 		}
		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-price.png'),
		// 	iconActive: require('../../assets/icon/menu-price-active.png'),
		// 	name: '定价管理',
		// 	path: '/price',
		// 	children: [
		// 		{
		// 			name: '定价列表',
		// 			path: '/price/price-list'
		// 		},
		// 		{
		// 			name: '添加定价',
		// 			path: '/price/price-edit'
		// 		}
		// 	]
		// },
        // {
		// 	icon: require('../../assets/icon/menu-dev.png'),
		// 	iconActive: require('../../assets/icon/menu-dev-active.png'),
		// 	name: '设备管理',
		// 	path: '/device',
		// 	children: [
		// 		{
		// 			name: '设备列表',
		// 			path: '/device/device-list',
		// 			children: []
		// 		},{
		// 			name: '设备类型列表',
		// 			path: '/device/device-type-list',
		// 			children: []
		// 		},{
		// 			name: '设备状态列表',
		// 			path: '/device/device-status-list',
		// 			children: []
		// 		}
		// 	]
		// },
  //       {
		// 	icon: require('../../assets/icon/menu-revenue.png'),
		// 	iconActive: require('../../assets/icon/menu-revenue-active.png'),
		// 	name: '收益',
		// 	path: '/revenueBills',
		// 	children: [
		// 		{
		// 			name: '收益账单',
		// 			path: '/revenueBills/revenueBills-list'
		// 		}
		// 	]
		// },
  //       {
		// 	icon: require('../../assets/icon/menu-chart.png'),
		// 	iconActive: require('../../assets/icon/menu-chart-active.png'),
		// 	name: '经营分析',
		// 	path: '/businessAnalysis',
		// 	children: [
		// 		{
		// 			name: '省份经营数据',
		// 			path: '/businessAnalysis/businessAnalysis-list'
		// 		},
  //               {
		// 			name: '城市经营数据',
		// 			path: '/businessAnalysis/businessAnalysis-list-city'
		// 		},
  //               {
		// 			name: '商户经营数据',
		// 			path: '/businessAnalysis/businessAnalysis-list-business'
		// 		},
  //               {
		// 			name: '项目经营数据',
		// 			path: '/businessAnalysis/businessAnalysis-list-project'
		// 		},
  //               {
		// 			name: '站点经营数据',
		// 			path: '/businessAnalysis/businessAnalysis-list-station'
		// 		}
		// 	]
		// },
  //       {
		// 	icon: require('../../assets/icon/menu-camera.png'),
		// 	iconActive: require('../../assets/icon/menu-camera-active.png'),
		// 	name: '现勘管理',
		// 	path: '/sceneCheck',
		// 	children: [
		// 		{
		// 			name: '现勘列表',
		// 			path: '/sceneCheck/sceneCheck-list'
		// 		}
		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-project.png'),
		// 	iconActive: require('../../assets/icon/menu-project-active.png'),
		// 	name: '项目管理',
		// 	path: '/project',
		// 	children: [
		// 		{
		// 			name:'项目列表',
		// 			path:'/project/project-list'
		// 		},{
		// 			name:'添加项目',
		// 			path:'/project/project-add'
		// 		}
		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-customer.png'),
		// 	iconActive: require('../../assets/icon/menu-customer-active.png'),
		// 	name: '客户管理',
		// 	path: '/customer',
		// 	children: [
		// 		{
		// 			name: '客户列表',
		// 			path: '/customer/customer-list'
		// 		},{
		// 			name: '新增客户',
		// 			path: '/customer/customer-add'
		// 		},

		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-userMGT.png'),
		// 	iconActive: require('../../assets/icon/menu-userMGT-active.png'),
		// 	name: '用户管理',
		// 	path: '/userMGT',
		// 	children: [
		// 		{
		// 			name: '用户列表',
		// 			path: '/userMGT/userMGT-list'
		// 		}

		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-goodsOrder.png'),
		// 	iconActive: require('../../assets/icon/menu-goodsOrder-active.png'),
		// 	name: '采购管理',
		// 	path: '/goodsOrder',
		// 	children: [
		// 		{
		// 			name: '采购列表',
		// 			path: '/goodsOrder/goodsOrder-list'
		// 		}

		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-company.png'),
  //           iconActive: require('../../assets/icon/menu-company-active.png'),
		// 	name: '加盟商',
		// 	path: '/franchisee',
		// 	children: [
		// 		{
		// 			name: '加盟商管理',
		// 			path: '/franchisee/franchisee-view'
		// 		},
		// 		{
		// 			name: '我的加盟商合同',
		// 			path: '/franchisee/franchisee-contract'
		// 		}
		// 	]
			
		// },
		// {
		// 	icon: require('../../assets/icon/menu-staff.png'),
  //           iconActive: require('../../assets/icon/menu-staff-active.png'),
		// 	name: '员工管理',
		// 	path: '/staffManage',
		// 	children: [
		// 		{
		// 			name: '员工管理',
		// 			path: '/staffManage/staff-manage'
		// 		}
		// 	]
			
		// },
		// {
		// 	icon: require('../../assets/icon/menu-withdraw.png'),
  //           iconActive: require('../../assets/icon/menu-withdraw-active.png'),
		// 	name: '提现管理',
		// 	path: '/withdrawal',
		// 	children: [
		// 		{
		// 			name: '提现管理',
		// 			path: '/withdrawal/withdrawal-view'
		// 		}
		// 	]
			
		// },{
		// 	icon: require('../../assets/icon/menu-todolist.png'),
		// 	iconActive: require('../../assets/icon/menu-todolist-active.png'),
		// 	name: '待办事项',
		// 	path: '/todolist',
		// 	children: [
		// 		{
		// 			name: '待办事项',
		// 			path: '/todolist/todolist-index'
		// 		}
		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-chargeCard.png'),
		// 	iconActive: require('../../assets/icon/menu-chargeCard-active.png'),
		// 	name: '充电卡管理',
		// 	path: '/chargeCard',
		// 	children: [
		// 		{
		// 			name: '基本信息',
		// 			path: '/chargeCard/chargeCard-list'
		// 		},
		// 		{
		// 			name: '在线卡管理',
		// 			path: '/chargeCard/chargeCard-onLine'
		// 		},
		// 		{
		// 			name: '离线卡管理',
		// 			path: '/chargeCard/chargeCard-offLine'
		// 		}
		// 	]
		// }
		// ,
		// {
		// 	icon: require('../../assets/icon/menu-gongdan.png'),
		// 	name: '样例页面',
		// 	path: '/test',
		// 	children: [
		// 		{
		// 			name: '列表页',
		// 			path: '/test/user'
		// 		}
		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-card.png'),
		// 	iconActive: require('../../assets/icon/menu-card-active.png'),
		// 	name: '月卡管理',
		// 	path: '/monthCard',
		// 	children: [
		// 		{
		// 			name: '月卡列表',
		// 			path: '/monthCard/monthCard-list',
		// 			children: []
		// 		},
		// 		{
		// 			name: '月卡客户',
		// 			path: '/monthCard/monthCard-relation',
		// 			children: []
		// 		}
		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-activity.png'),
		// 	iconActive: require('../../assets/icon/menu-activity-active.png'),
		// 	name: '活动配置',
		// 	path: '/activity',
		// 	children: [
		// 		{
		// 			name: '活动列表',
		// 			path: '/activity/activity-list',
		// 			children: []
		// 		},
		// 	]
		// },
		// {
		// 	icon: require('../../assets/icon/menu-revenue.png'),
		// 	iconActive: require('../../assets/icon/menu-revenue-active.png'),
		// 	name: '财务管理',
		// 	path: '/finance',
		// 	children: [
		// 		{
		// 			name: '收支流水',
		// 			path: '/finance/finance-szls-list',
		// 			children: []
		// 		},
		// 		{
		// 			name: '收益账单',
		// 			path: '/finance/finance-syrz-list',
		// 			children: []
		// 		},
		// 		{
		// 			name: '套餐账单',
		// 			path: '/finance/finance-package-list',
		// 			children: []
		// 		},
		// 		{
		// 			name: '账期管理',
		// 			path: '/finance/finance-zq-list',
		// 			children: []
		// 		}
		// 	]
		// },
        // {
		// 	icon: require('../../assets/icon/menu-devUpdate.png'),
		// 	iconActive: require('../../assets/icon/menu-devUpdate-active.png'),
		// 	name: '设备软件更新',
		// 	path: '/tools',
		// 	children: [
		// 		{
		// 			name: '设备软件更新',
		// 			path: '/tools/updateDevSoftware',
		// 			children: []
		// 		},
		// 	]
		// },
        // {
		// 	icon: require('../../assets/icon/menu-electric.png'),
		// 	iconActive: require('../../assets/icon/menu-electric-active.png'),
		// 	name: '电费管理',
		// 	path: '/electric',
		// 	children: [
		// 		{
		// 			name: '电费项目列表',
		// 			path: '/electric/electric-list'
		// 		},
        //      {
        // 			name: '物业管理列表',
        // 			path: '/electric/electric-property-list'
        // 		},
        //      {
        // 			name: '自缴电费管理',
        // 			path: '/electric/electric-charge-list'
        // 		}
		// 	]
		// },
        // {
		// 	icon: require('../../assets/icon/menu-task.png'),
		// 	iconActive: require('../../assets/icon/menu-task-active.png'),
		// 	name: '任务审核',
		// 	path: '/task',
		// 	children: [
		// 		{
		// 			name: '任务审核列表',
		// 			path: '/task/task-datas-list'
		// 		}
		// 	]
		// },
	],
	routeHis: [],
    menuList: []
}
const mutations = {
	setMenu: (state, menu) => {
		state.menu.splice(0)
		menu.map(item => {
			state.menu.push(item)
		})
	},
    setMenuId: (state, menuList) => {
		state.menuList = menuList;
	},
	
	addRouteHis: (state, route) => {
		state.routeHis.push(route)
	},

}

const actions = {
	setMenu({
		commit
	}, data) {
		commit('setMenu', data)
	},
    setMenuId({
		commit
	}, data) {
		commit('setMenuId', data)
	},
	addRouteHis({
		commit
	}, data) {
		commit('addRouteHis', data)
	},
	
	getMenu({
		commit
	}, data) {
		return new Promise(async (resolve, reject) => {

			try {
				let arg = {
					url: Config.apiUrl.getUserMenu,
					showLoading:false,
				};
				const {
					result
				} = await _get(arg)
				if (result.code === 0) {
                    let menuIdObj = {}
                    let menuIdList = []
                    result.data.map(item=>{
                        menuIdObj[item.id] = item.children.map(ite=>{
                            return ite.id
                        })
                        menuIdObj[item.id].push(item.id)
                    })
                    for(let key in menuIdObj) {
                        let item = menuIdObj[key]
                        menuIdList = menuIdList.concat(item)
                    }
					commit('setMenu', result.data);
					commit('setMenuId', menuIdList);
				} else {
					this.$message.error(result.msg || result.message)
				}
	
				resolve(result.data)
			} catch (err) {
				reject(err)
			}
		
		})
	},
}


export default {
	namespaced: true,
	state,
	actions,
	mutations
}
