const state = {
    showExportTips: false, // 下载成功tips
	showExportDialog: false, // 下载弹窗，设置导出文件名称等
	exportParams: {
		fileName: '',
		code: '',
		startTime: '',
        endTime: '',
		params: {}
	}
}


const actions = {
    
	setShowTips({
	    commit
	}, data) {
	    commit('setShowTips', data)
	},

	setExportDialog({
	    commit
	}, data) {
	    commit('setExportDialog', data)
	},

	setExportParams({
	    commit
	}, data) {
	    commit('setExportParams', data)
	},
}

const mutations = {
    setShowTips(state, data) {
		state.showExportTips = true;
		setTimeout(()=>{
			state.showExportTips = false;
		},3000)
    },

	setExportDialog(state, data) {
		state.showExportDialog = data;
    },

	setExportParams(state, data) {
		state.exportParams = {
			...data
		};
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}