<template>
    <div>
        <el-image-viewer v-if="showPreviewImg" :on-close="onClose" :url-list="imgList" :initial-index="showIndex" />
    </div>
</template>

<script>
export default {
    model: {
        prop: 'showPreviewImg',
        event: 'showPreviewImgChange'
    },
    components: {
        'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
    },
    props: {
        showPreviewImg: {
            type: Boolean,
            default: false
        },
        imgList: {
            type: Array,
            default: []
        },
        showIndex: {
            type: Number,
            default: 0
        }
    },
    methods: {
        onClose() {
            this.$emit('showPreviewImgChange', false)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>