<template>
    <div>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="750px">
            <el-table :data="tableData">
                <el-table-column label="序号" prop="sn" min-width="80"></el-table-column>
                <el-table-column label="状态" prop="success" min-width="200">
                    <template slot-scope="{ row }">
                        <span>{{ row.success?'成功':'失败' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="原因" prop="failMsg" min-width="200"></el-table-column>
            </el-table>
            <div slot="footer">
                <el-button type="primary" @click="dialogVisible = false">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false
            };
        },
        props:['tableData']
    }
</script>