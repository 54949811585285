import {
	_post,
	_get
} from "@/axios/func";
import Config from "@/config";
import Utils from "@/utils";
import getMenuList from '@/utils/menu';

let newsnumObj={
	newslist:
		[{
			keyType:'',
			notReadNum:0
		}],
		totalNum:0
	
}
const state = {
    newsnum:{
        ...newsnumObj
    }
	
}

const mutations = {
	setNewsNum: (state, newsnumObj) => {
		state.newsnum = newsnumObj
	}
}

const actions = {

	getNewsNum({
		commit
	}) {
		return new Promise(async (resolve, reject) => {
			try {
				let arg = {
					url: Config.apiUrl.getNotifyNum,
					params: {},
					showLoading:false,
				};
				const {
					result
				} = await _get(arg)

				if (result.code === 0) {
					const data = result.data;
					if(!!data.notifyKeyTypeRes&&data.notifyKeyTypeRes.length){
						newsnumObj.newslist=data.notifyKeyTypeRes
						newsnumObj.totalNum=data.notifyNotReadTotalNum
					}
			
		
         
					commit('setNewsNum', newsnumObj)
					resolve(newsnumObj)
				}
				
			} catch (err) {
				reject(err);
			}
		});
	},



}


export default {
	namespaced: true,
	state,
	actions,
	mutations
}
