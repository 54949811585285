import dayjs from 'dayjs'

export default {
    // 获取当前日期
    getToday (format="YYYY-MM-DD") {
		return dayjs(dayjs().startOf("day").valueOf()).format(format);
    },
    // 获取当前日期 时间为23:59:59
    getTodayLastTime (format="YYYY-MM-DD") {
		let date = dayjs(dayjs().startOf("day").valueOf()).format('YYYY-MM-DD');
        date += ' 23:59:59'
        if(format == 'YYYY-MM-DD') {
            return date
        }else {
            date = dayjs(date).valueOf()
            return date
        }
    },
    // 获取那年年今日
    getTodayThatYear (num = 1,format='YYYY-MM-DD') {
        return dayjs().subtract(num, 'year').format(format);
    },
    // 获取当前n个月前
    getTodayBeforeMonth (num,format='YYYY-MM-DD') {
        return dayjs().subtract(num, 'month').format(format);
    },
    // 获取当前N天前
    getTodayBeforeDays (num,format='YYYY-MM-DD') {
        return dayjs().add(-num, 'd').format(format)
    },
    // 获取两个日期之间所有日期
    enumerateDaysBetweenDates (start,end) {
        let arr = []
        let startTime = dayjs(start).valueOf()
        let endTime = dayjs(end).valueOf()
        while (startTime <= endTime) {
            arr.push(dayjs(startTime).format('YYYY-MM-DD'))
            startTime = dayjs(startTime).add(1, 'days').format('YYYY-MM-DD')
            startTime = dayjs(startTime).valueOf()
        }
        return arr
    },
    // 获取两个日期之间所有月份
    enumerateMonthsBetweenDates (start,end) {
        // let arr = []
        // let startDate = dayjs(start).valueOf()
        // let endDate = dayjs(end).valueOf()
        // while (endDate > startDate) {
        //     arr.push(startDate.format('YYYY-MM'));
        //     startDate.add(1,'month');
        // }
        // 初始化一个日期数组
        const months = [];
        // 创建一个日期对象
        let current = dayjs(start);
        // 循环直到结束日期
        while (current.isBefore(end) || current.isSame(end, 'month')) {
            // 将当前月份的信息添加到数组中
            months.push(current.format('YYYY-MM'));
            
            // 将当前日期增加一个月
            current = current.add(1, 'month');
        }
        return months
    },
    //获取某月的第一天和最后一天
	getSomeMonthDays (date,format='YYYY-MM-DD') {
		let obj = {
		    starttime: '',
		    endtime: ''
		}
		obj.starttime = dayjs(date).startOf('month').format(format);
		obj.endtime = dayjs(date).endOf('month').format(format);
		return obj
	},
    getTimeUnix (date) {
        return dayjs(date).valueOf() 
    },
    getTimeStampToStr (timeStamp, format= 'YYYY-MM-DD HH:mm:ss') {
        return dayjs(timeStamp).format(format);
    },
    // 获取两个时间点相差多少个小时
    getTimeDiff (startTime, endTime) {
        const date1=dayjs(`2000-01-01 ${startTime}:00`)
        const date2=dayjs(`2000-01-01 ${endTime}:00`);
        const date3=date2.diff(date1,'minute');//计算相差的分钟数
        let h=Math.floor(date3/60);//相差的小时数
        if(h<0){
            h = 24 + h
        }
        return h
    },
    getDateTimeDiff (startTime, endTime) {
        let time1 = dayjs(startTime).valueOf() 
        let time2 = dayjs(endTime).valueOf() 
        let diff = time2 - time1

        let diffH = Math.floor(diff / 1000 / 60 / 60)

        return diffH
    }
}