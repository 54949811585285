<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <le-pagview ref="stationPage" @setData="setTableData" :pageParam="pageParam" :tableRef="$refs.stationPagelist">
                    <el-table
                        ref="stationPagelist"
                        :data="tabledata" 
                        style="width:100%;height:100%">
                        <el-table-column label="站点ID" prop="typeText" width='80'>
                            <template slot-scope="{ row }">
                                <span>{{ row.id?row.id:'-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="站点名称" prop="content">
                            <template slot-scope="{ row }">
                                <span>{{ row.name || '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </le-pagview>
            </div>
            <div slot="footer">
                <el-button type="primary"  @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                // tabledata: [],
                pageParam: {
                    url: this.$Config.apiUrl.couponPage,
                    method: "post",
                    params: {
                        name: '',//
                        id: '', //id
                    },
                    freshCtrl: 1,
                },
            };
        },
        props: {
            title: {
                default: '可用站点'
            },
            tabledata: {
                default: ()=>{
                    return []
                }
            }
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>