<template>
	<el-dialog title="选择定价" :visible.sync="dialogVisible">
		<div class="a-flex-rfsc">
			<le-input-icon-search placeholder="请输入定价名称" v-model="pageParam.params.searchKey"></le-input-icon-search>
		</div>
		<div class=" a-mt-24">
			<le-pagview @setData="setTableData" :pageParam="pageParam">
				<el-table :data="priceList" height="425">
					<el-table-column property="name" label="定价名称"></el-table-column>
                    <el-table-column label="定价类型" min-width="120">
						<template slot-scope="{ row }">
							<span>{{ row.type }}</span>
						</template>
					</el-table-column>
					<el-table-column label="定价类型" min-width="120">
						<template slot-scope="{ row }">
							<span>{{ row.type | initDic(chargingPlanTypeDic) }}</span>
						</template>
					</el-table-column>
					<el-table-column label="起步时长">
						<template slot-scope="{ row }">
							<span v-if="row.type != '2' && row.type != '5' && row.type != '7' && row.type != '9' && row.type != '10' && row.type != '11'">{{ row.baseMinutes % 60 == 0 ? (row.baseMinutes / 60 + '小时') : (row.baseMinutes + '分钟') }}</span>
							<!-- <span v-else-if="row.type == '5'">{{ row.lowChoice % 60 == 0 ? (row.lowChoice / 60 + '小时') : (row.lowChoice + '分钟') }}</span> -->
                            <span v-else>-</span>
						</template>
					</el-table-column>
					<el-table-column label="起步费用/电费" min-width="140">
						<template slot-scope="{ row }">
							<span v-if="row.type != '2' && row.type != '5' && row.type != '7' && row.type != '9'&& row.type != '10' && row.type != '11'">{{ util.numFormat(row.baseAmount) }}元</span>
							<span v-else>{{ util.numFormatExcept10To5(row.baseAmount) }}元/kwh</span>
						</template>
					</el-table-column>
					<el-table-column label="加时费用" min-width="120">
						<template slot-scope="{ row }">
							<span v-if="row.type != '2' && row.type != '5' && row.type != '7' && row.type != '9'&& row.type != '10' && row.type != '11'">
                                {{ util.numFormat(row.stepAmount) }}元/
                                {{ row.stepMinutes % 60 == 0 ? (row.stepMinutes / 60 + '小时') : (row.stepMinutes +'分钟')}}
                            </span>
							<span v-else>-</span>
						</template>
					</el-table-column>
                    <!-- <el-table-column label="服务费" min-width="140">
						<template slot-scope="{ row }">
							<span v-if="row.type == '2' || row.type == '3' || row.type == '4' || row.type == '7'">
                                {{ util.numFormat(row.serviceBaseFee) }}元/
                                {{ row.serviceBaseTime % 60 == 0 ? (row.serviceBaseTime / 60 + '小时') : (row.serviceBaseTime +'分钟')}}
                            </span>
                            <span v-else-if="row.type == '5' || row.type == '10' || row.type == '11'">
                                {{ util.numFormat5(row.serviceBaseFee) }}元/0.1kwh
                            </span>
							<span v-else>-</span>
						</template>
					</el-table-column> -->
                    <el-table-column label="服务费" min-width="150">
						<template slot-scope="{ row }">
							<span v-if="row.type == '2' || row.type == '7' || row.type == '12'">
                                {{ util.numFormat(row.chargeBaseFee) }}元/
                                {{ row.chargeBaseTime % 60 == 0 ? (row.chargeBaseTime / 60 + '小时') : (row.chargeBaseTime +'分钟')}}
                            </span>
                            <span v-else-if="row.type == '5' || row.type == '10' || row.type == '11'">
                                {{ util.numFormatExcept10To5(row.chargeBaseFee) }}元/kwh
                            </span>
							<span v-else>-</span>
						</template>
					</el-table-column>

                    <el-table-column label="占位费" min-width="150">
						<template slot-scope="{ row }">
							<span v-if="row.serviceBaseFee && row.serviceBaseFee !== 0 && row.serviceBaseFee !== '0'">
                                {{ util.numFormat(row.serviceBaseFee) }}元/
                                {{ row.serviceBaseTime % 60 == 0 ? (row.serviceBaseTime / 60 + '小时') : (row.serviceBaseTime +'分钟')}}
                            </span>
                            <span v-else>-</span>
						</template>
					</el-table-column>
					<el-table-column label="免费时长">
						<template slot-scope="{ row }">
							<span>{{ row.hesitationMinutes?row.hesitationMinutes:'-' }}分钟</span>
						</template>
					</el-table-column>
					<el-table-column label="定价状态">
						<template slot-scope="{ row }">
							<span>{{ row.status == '0' ? '启动中' : '已停用' }}</span>
						</template>
					</el-table-column>

					<el-table-column label="操作" width="80" fixed='right'>
						<template slot-scope="{ row }">
							<el-button type="info" plain @click="handlerChoosePrice(row)">选择</el-button>
						</template>
					</el-table-column>
				</el-table>
			</le-pagview>
		</div>

	</el-dialog>
</template>
<script>
import util from '../../../utils/util.js'
/**
 * 现勘选择
 */
export default {
	props: {
		visible: {
			required: true,
			type: Boolean,
			default: false,
		},
		priceId: {
			required: false,
		},
		priceName: {
			required: false,
		},
	},
	data() {
		return {
			util: util,
			dialogVisible: false,


			pageParam: {
				url: this.$Config.apiUrl.getPlanList,
				method: "post",
				params: {
					searchKey: '',
				},
			},
			priceList: [],
            chargingPlanTypeDic: []
		};
	},
	watch: {
		visible: {
			handler(val) {
				this.dialogVisible = val;
			},
		},
		dialogVisible: {
			handler(val, oldval) {
				if (val != oldval) {
					this.$emit("update:visible", val)
				}
			}
		},
	},
	mounted() {
        this.$getDic('chargingPlanType','select').then(res=>{ this.chargingPlanTypeDic = res; })
	},
	methods: {

		handlerChoosePrice(item) {
			console.log('handlerChooseStation', item)
			this.dialogVisible = false
			this.$emit("update:priceId", item.id)
			this.$emit("update:priceName", item.name)
		},
		setTableData(data) {
			this.priceList = data;
		},

	},
};
</script>
