import {
	_post,
	_get
} from "@/axios/func";
import Config from "@/config";
import Utils from "@/utils";



const initInfo = {
	accountName: "",
	accountNumber: "",
	accountType: 0,
	adminId: 0,
	agencyLevel: 0,
	applyTime: 0,
	balance: 0,
	balanceLock: 0,
	balanceWaitConfirm: 0,
	bankBranch: "",
	bankId: null,
	bankName: "",
	brand: "",
	certImagePath: "",
	certNumber: "",
	certType: null,
	cityCode: "",
	createTime: 0,
	creditCode: "",
	districtCode: "",
	establishTime: null,
	id: 2,
	is3in1: 1,
	isDelete: 0,
    isSystem: 0,
	logoPath: "",
	name: "",
	newType: 0,
	orgImagePath: "",
	orgNumber: "",
	orgType: null,
	parentId: null,
	provinceCode: "",
	regFeedback: "",
	regState: 0,
	registrantId: 0,
	repIdNumber: "",
	repName: "",
	source: "",
	status: 0,
	type: 0,
	updateTime: 0,
	vault: ""
}
const state = {
	company: {
		...initInfo
	
	}
}

const mutations = {
	setCompany: (state, company) => {
		state.company = company
	}

}


const actions = {
	/**
	 * 重置商户vuex
	 */
	resetCompany({
		commit
	}) {
		commit('setCompany', initInfo);
	},


	/**
	 * 获取当前商户默认商户
	 */
	getDefaultCompanyInfo({
		commit
	}) {
		return new Promise(async (resolve, reject) => {

			if (this.state.company.id >0) {
				resolve(this.state.company)
			} else {
				try {
					let arg = {
						url: Config.apiUrl.getDefaultCompanyInfo,
						showLoading:false,
					};
					const {
						result
					} = await _get(arg)
					if (result.code === 0) {
						const data = result.data;
						commit('setCompany', data);
						Utils.setCookie('companyId', data.id)
					} else {
						this.$message.error(result.msg || result.message)
					}

					resolve(result.data)
				} catch (err) {
					reject(err)
				}
			}

		})
	},
	
	
	/**
	 * 获取当前商户默认商户
	 */
	getCompanyInfo({
		commit
	}) {
		return new Promise(async (resolve, reject) => {
	
			try {
				let arg = {
					url: Config.apiUrl.getDefaultCompanyInfo,
                    showLoading:false,
				};
				const {
					result
				} = await _get(arg)
				if (result.code === 0) {
					const data = result.data;
					commit('setCompany', data);
					Utils.setCookie('companyId', data.id)
				} else {
					this.$message.error(result.msg || result.message)
					this.Utils.removeToken()
				}

				resolve(result.data)
			} catch (err) {
			
				reject(err)
			}
	
		})
	},
	


}


export default {
	namespaced: true,
	state,
	actions,
	mutations
}
