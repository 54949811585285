<template>
	<le-select-remote-search :label="label" v-model="myValue" :options="myOptions"
		:placeholder="placeholder" :notnull="notnull" :large="large" />
</template>


<script>
	/**
	 * 获取没有签署加盟商协议的商户列表
	 */
	export default {
		props:{
			value:{
				required:true,
			},
			placeholder:{
				type:String,
				default:'请选择'
			},
			label:{
				type:String
			},
			/**
			 * 是否必填
			 */
			notnull:{
				type:Boolean,
				default:false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large:{
				type:Boolean,
				required:false,
				default:false
			}
			
		},
		data() {
			return {
				myValue:'',
				myOptions:{
					url: this.$Config.apiUrl.getNoAgencyCompany,
					method: "post",
					params: {
						isSignAgency: 1,
					},
					labelKey: "name",
					valueKey: "id",
					searchKey: "key",
				}
			}
		},
		watch:{
			value:{
				handler(val){
					this.myValue = val
				},
				immediate:true
			},
			myValue:{
				handler(val,oldVal){
					if(val!=oldVal){
						this.$emit('input',val)
					}
				}
			}
		},
		mounted() {
		},
		methods:{
			
		}
	}
</script>

<style>
</style>
