const filters = {
    initDic (value,dic) {
		if(value || value === 0){
			for(var i=0; i<dic.length;i++){
				if(value==dic[i].value){
					return dic[i].text || dic[i].label
				}
			}
		}else{
			return '-'
		}
		
	},
    initTablelabel (val, dic, defaultText) {
        let obj = dic.find(res=>{
            return res.value === val
        })
        return obj ? obj.text : defaultText
    },
    // 秒 换算 小时分钟
    secondToHoursMinute (val) {
        let hour = parseInt(val / 60 / 60)
        let minute = parseInt((val - hour * 60 * 60) / 60)
        return (hour?hour+'H':'') + (minute?minute+'m':'')
    }
}
export default (Vue) => {
    Object.keys(filters).forEach(key => {
        Vue.filter(key, filters[key])
    })
}