import Vue from "vue";
import Vuex from "vuex";
import tagsView from "./modules/tagsView";
import user from "./modules/user";
import company from "./modules/company";
import hamburger from "./modules/hamburger";

import menu from "./modules/menu.js";
import todonum from "./modules/todonum.js";
import newsnum from "./modules/news.js";
import exportFile from "./modules/exportFile.js"

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user,
        company,
        tagsView,
        menu,
        todonum,
        newsnum,
        hamburger,
        exportFile,
    },
    //getters
});

export default store;
