<template>
    <el-scrollbar
        wrap-class="scrollbar-wrapper my-menu"
        style="height: 100%; overflow-x: hidden">
        <div class="searchBox">
            <el-input class="search" v-model="searchContent" @input='handleSearch' placeholder="搜索菜单"></el-input>
        </div>
        <el-menu
            @select='handleSelectMenu'
            :collapse="!sidebarOpened"
            unique-opened
            ref="AsideElMenu"
            :show-timeout="100"
            :hide-timeout="100"
            :default-active="$route.path"
            mode="vertical"
            text-color="#303133"
            active-text-color="#007AFF"
            menu-trigger="click"
            :collapse-transition="true"
            :default-openeds='defaultOpeneds'
            router>
            <sidebar-item
                v-for="(item, index) in menu"
                v-show="isShowMenu(item)"
                :key="index"
                :index="index"
                :item="item"
                :base-path="item.path"/>
        </el-menu>
    </el-scrollbar>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import path from "path";
import SidebarItem from "./SidebarItem";

export default {
    components: {
        SidebarItem,
    },
    data() {
        return {
            searchContent: '',
            collapseTransition: true,
            defaultOpeneds: []
        };
    },
    computed: {
        ...mapState({
            menu: (state) => state.menu.menu,
            sidebarOpened: (state) => state.hamburger.sidebarOpened,
        }),
    },
    watch: {
        menu:{
            deep: true,
            immediate: false,
            handler (val) {
                // this.openMenu()
            }
        }
    },
    methods: {
        isShowMenu (datas) {
            if(this.searchContent) {
                let isChild = datas.children.find(re=>{
                    return re.name.indexOf(this.searchContent) != -1
                })
                if(isChild || datas.name.indexOf(this.searchContent) != -1) {
                    this.$set(datas, 'isShow', true);
                    return true
                }else {
                    this.$set(datas, 'isShow', false);
                    return false
                }
                
            }else {
                this.$set(datas, 'isShow', true);
                return true
            }
        },
        handleSearch () {
            if(this.searchContent) {
                this.$nextTick(()=>{
                    this.openMenu()
                })
            }
        },
        openMenu () {
            let menu = this.menu.find(res=>{
                return res.isShow
            })
            if(menu) {
                let index = menu.children.length > 1 ? this.resolvePath(menu.children[0].path) : menu.children[0].path
                this.defaultOpeneds = [index]
            }else {
                this.defaultOpeneds = [$route.path]
            }
        },
        handleSelectMenu () {
            this.searchContent = ''
        },
        resolvePath(routePath) {
            if (this.isExternalLink(routePath)) {
                return routePath;
            }
            return path.resolve(this.basePath, routePath);
        },
        isExternalLink(routePath) {
            return this.$Utils.isExternal(routePath);
        },
    }
};
</script>
<style lang="scss" scoped>
.scrollbar-wrapper {
    width: 232px;
    box-shadow: 0px 2px 10px 0px #ebeff5;
    overflow-x: hidden !important;

    & .is-horizontal {
        display: none;
    }

    /deep/ .el-scrollbar__bar {
        display: none !important;
    }
}
.my-menu {
    overflow-x: hidden !important;
}
.searchBox{
    padding: 0 10px;
    width: 157px;
    .search{
        width: 100%;
    }
}


// ::-webkit-scrollbar {

// 	display: none !important;
// 	/* Chrome Safari */

// }
// .my-menu {

// 	/deep/ .el-scrollbar__bar{
// 		display: none !important;
// 	}
// }
</style>
