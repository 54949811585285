<template>
    <div ref="echartsRef" 
        :style="{ 
            'width': 'auto',
            'height': echartsObj.height? echartsObj.height : 'calc(100vh - 630px)',
            'margin-top': '34px',
            'min-height': '258px',
        }">
    </div>

</template>
<script>
import config from '../../../utils/util'
export default {
    props: {
        echartsObj: {
            type: Object,
        }
    },
    data() {
        return {
            echarts: null,
            echartOptions: {

            },
			color: ['#FFA900', '#6699FF', '#FF3030', '#FF9C00', '#FFD700','#3ba272'],
			inactiveColor: ['#FFA900', '#6699FF', '#FF3030', '#FF9C00', '#FFD700','#3ba272'],
        }
    },
    watch: {
        echartsObj: {
            handler(newval, oldval) {
                if (newval) {
                    setTimeout(() => {
                        this.drawChart(this.echartsObj,this.echartsObj.xAxisData, this.echartsObj.seriesData0, this.echartsObj.seriesData1, this.echartsObj.seriesName0, this.echartsObj.seriesName1, this.echartsObj.echartsType)
                    }, 500)
                }

            },
            deep: true,
            immediate: false
        }
    },
    methods: {
        drawChart(echartsObj,xAxis = [], data0 = [], data1 = [], name0 = '交易额', name1 = '订单量', type = '') {
            var chartDom = this.$refs['echartsRef'];
            var option;
            option = {
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    data: Object.keys(echartsObj).filter(key=>{
						return key.indexOf('seriesName') !=-1
					}).map(key=>{
						return {
							name: echartsObj[key], icon: "roundRect"
						}
					}),
                    right: "2%",
                    itemGap: 40
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgba(255, 255, 255, 0.96)',
                    extraCssText: 'height:auto;box-shadow: 0px 4px 16px 0px rgba(139, 147, 167, 0.25);',
                    textStyle: {
                        color: '#303133',
                    },
                    padding: 10,
                    formatter: function (params) {
						// console.log('formatter',params)
						
						let art = `<div style="padding-left:10px">
                            <div class="a-fs-14 a-c-666">${params[0].axisValue}</div>`
						
						params.map((item,index)=>{
							art += 
							`<div class="a-fw-700">
                                <span class="a-fs-14 a-c-666 a-fw-400">${item.seriesName}：</span>
                                <span class="a-fs-18 a-c-333 a-fw-500">${item.value}</span>
                                <span class="a-fs-12 a-c-666 a-fw-400">${echartsObj['seriesUnit'+(index)]?echartsObj['seriesUnit'+(index)]:''}</span>
							</div>`
						})
						art += `</div>`
                        return art
                    }
                },
                color: this.color,
                grid: {
                    left: '1%',
                    right: '2%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xAxis,
                    boundaryGap: true,
                    axisLine: {
                        lineStyle: {
                            color: '#ddd'
                        }
                    },
                    axisTick: {
                        lineStyle: {
                            color: "#ddd",
                            length: 4
                        },
                        alignWithLabel: true,

                    },
                    axisLabel: {
                        textStyle: {
                            color: "#606366",

                        },
                        // fontStyle: 'italic',
                        formatter: (value, index)=> {
                            if(this.echartsObj.useMineAxisLabel){
                                return value
                            }
                            return value.split('-')[1] + '/' + value.split('-')[2]
                        }
                    }

                },
                yAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                width: 1,
                                color: "#eee"
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#606366"
                            }
                        },
                        name: this.echartsObj.yName ? this.echartsObj.yName : '',
                        nameTextStyle: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            lineHeight: 36
                        }
                    },{
                        show: this.echartsObj['yAxisIndex']?true:false,
                        type: 'value',
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                width: 1,
                                color: "#eee"
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#606366"
                            }
                        },
                        name: this.echartsObj.yName ? this.echartsObj.yName : '',
                        nameTextStyle: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            lineHeight: 36
                        }
                    },{
                        show: this.echartsObj['yAxisIndex']?true:false,
                        type: 'value',
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                width: 1,
                                color: "#eee"
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#606366"
                            }
                        },
                        name: this.echartsObj.yName ? this.echartsObj.yName : '',
                        nameTextStyle: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            lineHeight: 36
                        }
                    }
                ],
                series: Object.keys(this.echartsObj).filter(item=>{
					return item.indexOf('seriesData')!=-1
				}).map((item,index)=>{
					return {
						symbol: 'none',
						name: this.echartsObj['seriesName'+index],
						data: this.echartsObj['seriesData'+index],
						type: 'line',
                        yAxisIndex: this.echartsObj['yAxisIndex'+index]?this.echartsObj['yAxisIndex'+index]:0,
						smooth: true,
						lineStyle: {
						    width: 3,
						    color: this.color[index],
						    // shadowColor: this.inactiveColor[index],
						    // shadowBlur: 7,
						    // shadowOffsetY: 6
						},
					}
				})

            }
            if (this.echarts) {
                this.echarts.dispose();
            }
            if (!!chartDom) {
                this.echarts = this.$echarts.init(chartDom);

                this.echarts.setOption(option, true);
            }

            window.addEventListener("resize", () => {
                if (this.echarts) {
                    this.echarts.resize()
                }
            })



        }
    }
}
</script>
<style>
</style>