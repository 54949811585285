<template>
	<div class="a-flex-rfsc a-ptb-12">
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span>{{ label }}</span>
		</div>
		<el-select class="a-ml-20 a-flex-1" :class="large ? 's-large-input' : 's-nomarl-input'" :value="value"
			filterable clearable :multiple="multiple" :collapse-tags="collapse" remote :placeholder="placeholder"
			:disabled="readonly" :reserve-keyword="reserveKeyword"
			:remote-method="remoteMethod" @blur="handlerBlur" @change="handlerChange" @clear="remoteMethod('')">
			<!-- @blur="remoteMethod('')" -->
            <!-- <el-option class="a-c-blue a-fw-700" label='全选' value='全选' @click.native='selectAllItem' v-if=""></el-option> -->
            <div class="a-flex-rfsc a-fw-700 addBankBtn" :class="selectedArr.length == myOptions.length? 'a-c-blue':'a-c-666'" @click="selectAllItem" v-if="selectAll && searchVal">
                <i class="el-icon-check a-fw-700"></i>
                <span class="a-plr-7">全选</span>
            </div>
            <div v-if="!isObj">
			    <el-option v-for="item in myOptions" :key="item.value" :label="item.label" :value="item.value" :disabled="!canUse && !item.signState">
                    <span>{{ item.label }}</span>
                    <el-tag class="a-ml-10" effect="dark" size='mini' v-if="item.signState == 1">已进件</el-tag>
                </el-option>
            </div>
            <div v-if="isObj">
			    <el-option v-for="item in myOptions" :key="item.value" :label="item.label" :value="item" value-key="value" :disabled="!canUse && !item.signState">
                    <span>{{ item.label }}</span>
                    <el-tag class="a-ml-10" effect="dark" size='mini' v-if="item.signState == 1">已进件</el-tag>
                </el-option>
            </div>
		</el-select>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	export default {
		props: {
			value: {
				required: true,
			},
			placeholder: {
				type: String,
				default: "请选择",
			},
			label: {
				type: String,
			},
			/**
			 * 是否必填
			 */
			notnull: {
				type: Boolean,
				default: false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large: {
				type: Boolean,
				required: false,
				default: false,
			},
			multiple: {
				type: Boolean,
				required: false,
				default: false
			},
			collapse: {
				type: Boolean,
				required: false,
				default: false
			},
            selectAll: {
                type: Boolean,
				required: false,
				default: false
            },
            reserveKeyword:{
                type: Boolean,
                default: false
            },
			readonly:{
				type:Boolean,
				default:false
			},
            isObj: {
                type: Boolean,
                default: false
            },
            canUse: {
                type: Boolean,
                default: true
            }
		},
		watch: {
			'options.params': {
				// 子级下拉框的监听对象
				deep: true,
				immediate: true,
				handler: function(val) {
					this.remoteMethod();
				},
			},
			'company.id':{
				deep: true,
				handler (newVal,oldVal) {
					// 切换商户后商户搜索id重置
					if(newVal != oldVal){
						this.options.params[this.options.searchKey] = ''
                        this.$emit("input", this.multiple?[]:'');
						this.remoteMethod();
					}
				}
			},
            value:{
                handler (val) {
                    this.selectedArr = val
                }
            }
		},
		data() {
			return {
				myOptions: [],
				selectLabel: '',
				myValue:'',
                selectedArr: [],
                searchVal: '',// 搜索输入值
                options: {
                    url: this.$Config.apiUrl.getFinanceCompanySelect,
                    method: "post",
                    showLoading: false,
                    params: {
                    },
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "companyName",
                }
			};
		},
		mounted() {
			this.remoteMethod();
		},
		computed:{
			...mapState({
				company: state => state.company.company
			})
		},
		methods: {
            selectAllItem() {
                if (this.selectedArr.length < this.myOptions.length) {
                    this.selectedArr = []
                    this.myOptions.map((item) => {
                        this.selectedArr.push(item.value)
                    })
                } else {   // 取消全选
                    this.selectedArr = [];
                }
                this.$emit("input", this.selectedArr);
            },
			handlerChange(e) {
				this.$emit("input", e);
			},
			// 下拉框失焦时清空搜索条件，并初始化下拉列表为无条件搜索结果列表
			handlerBlur () { 
                if(!this.selectAll){
                    setTimeout(()=>{
                        this.options.params[this.options.searchKey] = ''
                        this.remoteMethod();
                    },200)
                }
			},
			remoteMethod(key) {
                this.searchVal = key?key:''
				let func = this.$Axios._post;
				if (this.options.method.toLowerCase() == "get") {
					func = this.$Axios._get;
				}
				let params = {
					...this.options.params,
					pageNum: 1,
					pageSize: 10000,
				};
				params[this.options.searchKey] = key;

				func({
					...this.options,
                    showLoading: false,
					params: params,
				}).then((res) => {
					let arr = res.result.data.list?res.result.data.list:res.result.data
					this.myOptions = arr.map((item) => {
						if (this.value == item[this.options.valueKey]) {
							this.selectLabel = item[this.options.labelKey]
						}
						return {
							label: item[this.options.labelKey],
							value: item[this.options.valueKey],
                            signState: item.signState
						};
					});
					
				});
			},
			// 仅用于商户下拉查询
			resetCompamyChoose () {
				this.remoteMethod()
			}
		},
	};
</script>

<style lang="scss" scoped>
    .addBankBtn{
        padding: 0 20px;
        font-size: 14px;
        line-height: 34px;
        cursor: pointer;
    }
</style>
