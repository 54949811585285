<template>
	<div class="a-flex-rfsc a-ptb-12">
		<div class="s-search-label a-flex-rfec" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<span>{{ label }}</span>
		</div>
		<el-select 
            class="a-flex-1" 
            ref="selectRef" 
            :loading="showLoading" 
            :class="[large ? 's-large-input' : 's-nomarl-input',label?'a-ml-20':'']" 
            :value="value"
			filterable 
            :clearable='clearable' 
            :multiple="multiple" 
            :collapse-tags="collapse" 
            :multiple-limit='multipleLimit'
            remote 
            :placeholder="placeholder"
			:disabled="readonly" 
            :reserve-keyword="reserveKeyword"
			:remote-method="remoteMethod" 
            @visible-change='visibleChange' 
            @blur="handlerBlur" 
            @change="handlerChange" 
            @clear="remoteMethod('')">
			<!-- @blur="remoteMethod('')" -->
            <!-- <el-option class="a-c-blue a-fw-700" label='全选' value='全选' @click.native='selectAllItem' v-if=""></el-option> -->
            <div class="a-flex-rfsc a-fw-700 addBankBtn" :class="selectedArr.length == myOptions.length? 'a-c-blue':'a-c-666'" @click="selectAllItem" v-if="selectAll && searchVal">
                <i class="el-icon-check a-fw-700"></i>
                <span class="a-plr-7">全选</span>
            </div>
            <div v-if="!isObj">
			    <el-option v-for="item in myOptions" :key="item.value" :label="item.label" :value="item.value" :disabled='optionDisabled(item.value)'></el-option>
            </div>
            <div v-if="isObj">
			    <el-option v-for="item in myOptions" :key="item.value" :label="item.label" :value="item" value-key="value" :disabled='optionDisabled(item.value)'></el-option>
            </div>
		</el-select>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	export default {
		props: {
			value: {
				required: true,
			},
			placeholder: {
				type: String,
				default: "请选择",
			},
			label: {
				type: String,
			},
			/**
			 * 是否必填
			 */
			notnull: {
				type: Boolean,
				default: false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large: {
				type: Boolean,
				required: false,
				default: false,
			},
			multiple: {
				type: Boolean,
				required: false,
				default: false
			},
            multipleLimit: {
                type: Number,
                default: 0
            },
			collapse: {
				type: Boolean,
				required: false,
				default: false
			},
            selectAll: {
                type: Boolean,
				required: false,
				default: false
            },
            reserveKeyword:{
                type: Boolean,
                default: false
            },
			readonly:{
				type:Boolean,
				default:false
			},
            clearable: {
                type:Boolean,
                default: true
            },
			options: {
				type: Object,
				required: true,
				validator: (param) => {
					if (!param.url) {
						console.log("le-select-remote-search 参数缺少url");
						return false;
					}
					if (!param.method) {
						console.log("le-select-remote-search 参数缺少method(post|get)");
						return false;
					}
					if (!param.params) {
						console.log("le-select-remote-search 参数缺少params");
						return false;
					}
					if (!param.labelKey) {
						console.log("le-select-remote-search 参数缺少labelKey");
						return false;
					}
					if (!param.valueKey) {
						console.log("le-select-remote-search 参数缺少valueKey");
						return false;
					}
					if (!param.searchKey) {
						console.log("le-select-remote-search 参数缺少searchKey");
						return false;
					}
					return true;
				},
			},
            isObj: {
                type: Boolean,
                default: false
            },
            defaultOptions: { //默认待选项，数据复现时使用
                default: ()=>{
                    return []
                }
            },
            disableOptions: {
                default: ()=>{
                    return []
                }
            },
            requestStart: { // false--在下拉框收起的时候请求数据，true--在下拉框展开时请求数据
                type: Boolean,
                default: false,
            }
		},
		watch: {
			'options.params': {
				// 子级下拉框的监听对象
				deep: true,
				// immediate: true,
				handler: function(val) {
					this.remoteMethod();
				},
			},
			'company.id':{
				deep: true,
				handler (newVal,oldVal) {
					// 切换商户后商户搜索id重置
					if(newVal != oldVal){
						this.options.params[this.options.searchKey] = ''
                        this.$emit("input", this.multiple?[]:'');
						this.remoteMethod();
					}
				}
			},
            value:{
                handler (val) {
                    this.selectedArr = val
                }
            },
            defaultOptions: {
                deep: true,
                handler () {
                    let arr = []
                    let optionsArr = JSON.parse(JSON.stringify(this.myOptions))
                    console.log(this.defaultOptions,'this.defaultOptions');
                    if(this.defaultOptions && this.defaultOptions.length) { //处理远程搜索select导致的数据复现问题
                        arr = this.defaultOptions.map((item) => {
                            return {
                                label: item[this.options.labelKey] + (item[this.options.otherLabelKey]?(' - ' + item[this.options.otherLabelKey]):''),
                                value: item[this.options.valueKey],
                            };
                        });
                        this.myOptions = this.uniqueArr(arr.concat(optionsArr), 'value') 
                    }
                }
            }
		},
		data() {
			return {
				myOptions: [],
				selectLabel: '',
				myValue:'',
                selectedArr: [],
                searchVal: '',// 搜索输入值
                showLoading: false
			};
		},
		mounted() {
			this.remoteMethod();
		},
		computed:{
			...mapState({
				company: state => state.company.company
			}),
		},
		methods: {
            selectAllItem() {
                if (this.selectedArr.length < this.myOptions.length) {
                    this.selectedArr = []
                    this.myOptions.map((item) => {
                        if((this.multipleLimit > 0 && this.multipleLimit && this.selectedArr.length < this.multipleLimit) || !this.multipleLimit) {
                            if(!this.isObj) {
                                this.selectedArr.push(item.value)
                            }else {
                                this.selectedArr.push(item)
                            }
                        }
                    })
                } else {   // 取消全选
                    this.selectedArr = [];
                }
                this.$emit("input", this.selectedArr);
            },
			handlerChange(e) {
                this.selectedArr = e //记录已选择项
				this.$emit("input", e);
			},
			// 下拉框失焦时清空搜索条件，并初始化下拉列表为无条件搜索结果列表
			handlerBlur () { 
                
			},
            visibleChange (event) {
                if(!this.requestStart) {
                    if(!this.selectAll && !event){
                        setTimeout(()=>{
                            this.options.params[this.options.searchKey] = ''
                            this.remoteMethod();
                        },200)
                    }
                }else {
                    if(!this.selectAll && event){
                        this.options.params[this.options.searchKey] = ''
                        this.remoteMethod();
                    }
                }
                if(!event) {
                    this.$refs.selectRef.blur();
                }
                
            },
			remoteMethod(key) {
                this.showLoading = true
                this.searchVal = key?key:''
				let func = this.$Axios._post;
				if (this.options.method.toLowerCase() == "get") {
					func = this.$Axios._get;
				}
				let params = {
					...this.options.params,
					pageNum: 1,
					pageSize: this.options.pageSize || 10000,
				};
				params[this.options.searchKey] = key;

				func({
					...this.options,
                    showLoading: false,
					params: params,
				}).then((res) => {
					let arr = res.result.data.list?res.result.data.list:res.result.data
                    if(this.defaultOptions && this.defaultOptions.length && !key) { //处理远程搜索select导致的数据复现问题
                        arr = this.uniqueArr(this.defaultOptions.concat(arr), this.options.valueKey) 
                    }
					this.myOptions = arr.map((item) => {
						if (this.value == item[this.options.valueKey]) {
							this.selectLabel = item[this.options.labelKey] + (item[this.options.otherLabelKey]?' - ' + item[this.options.otherLabelKey]:'')
						}
						return {
                            ...item,
							label: item[this.options.labelKey] + (item[this.options.otherLabelKey]?' - ' + item[this.options.otherLabelKey]:''),
							value: item[this.options.valueKey],
						};
					});
					this.showLoading = false
				}).catch(err=>{
                    this.showLoading = false
                })
			},
			// 仅用于商户下拉查询
			resetCompamyChoose () {
				this.remoteMethod()
			},
            // 待选项是否可选
            optionDisabled (item) {
                if(this.multiple) {
                    if(this.disableOptions.indexOf(item) != -1 && this.selectedArr.indexOf(item) == -1) {
                        return true
                    }else {
                        return false
                    }
                }else {
                    if(this.disableOptions.indexOf(item) != -1 && this.selectedArr != item) {
                        return true
                    }else {
                        return false
                    }
                }
            },
            uniqueArr (arr, key) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i][key]==arr[j][key]){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
                // return [...new Set(arr.map(t=>JSON.stringify(t)))].map(s=>JSON.parse(s))
            },
		},
	};
</script>

<style lang="scss" scoped>
    .addBankBtn{
        padding: 0 20px;
        font-size: 14px;
        line-height: 34px;
        cursor: pointer;
    }
</style>
