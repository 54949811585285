<template>
    <div v-if="!item.hidden" class="menu-wrapper">
        <!-- <template
			v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
			<app-link :to="resolvePath(onlyOneChild.path)">
				<el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">

					<img :src="onlyOneChild.icon" class="a-wh-16" />
					<span class="a-ml-08 ">{{onlyOneChild.name}}</span>
				</el-menu-item>
			</app-link>
		</template> -->
        <el-submenu
            :index="resolvePath(item.children[0].path)"
            v-if="item.children.length >= 1 && item.path != '/home'"
        >
            <template slot="title">
                <item
                    :title="item.name"
                    :icon="
                        $route.path.split('/')[1] != item.path.split('/')[1]
                            ? item.icon
                            : item.iconActive
                    "
                    :colorClass="
                        $route.path.split('/')[1] !=
                        item.children[0].path.split('/')[1]
                            ? 'a-c-master'
                            : 'a-c-blue'
                    "
                />
            </template>
            <sidebar-item
                v-for="child in item.children"
                :is-nest="true"
                :item="child"
                :key="child.path"
                :base-path="resolvePath(child.path)"
                class="nest-menu"
            />
            <!-- <app-link
                    v-else
                    :to="resolvePath(child.path)"
                    :key="child.name"
                    :underline="false"
                >
                    <el-menu-item :index="resolvePath(child.path)">
                        <item v :title="child.name" />
                    </el-menu-item>
                </app-link> -->
        </el-submenu>

        <el-menu-item
            v-else
            :index="item.children.length ? item.children[0].path : item.path"
            class="menuitemnobody"
        >
            <item
                :title="item.name"
                :icon="
                    $route.path.split('/')[1] != item.path.split('/')[1]
                        ? item.icon
                        : item.iconActive
                "
                :colorClass="
                    $route.path.split('/')[1] != item.path.split('/')[1]
                        ? 'a-c-master'
                        : 'a-c-blue'
                "
            />

            <el-badge
                :value="todonum.totalNum"
                :max="99"
                class="itembadge"
                v-if="item.name == '待办事项' && todonum.totalNum"
            >
            </el-badge>
        </el-menu-item>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import path from "path";
import { isExternal } from "@/utils";
import Item from "./Item";
import AppLink from "./Link";

export default {
    name: "SidebarItem",
    components: {
        Item,
        AppLink,
    },
    props: {
        // route object
        item: {
            type: Object,
            required: true,
        },
        isNest: {
            type: Boolean,
            default: false,
        },
        basePath: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            onlyOneChild: null,
        };
    },
    computed: {
        ...mapState({
            todonum: (state) => state.todonum.todonum,
        }),
    },
    methods: {
        hasOneShowingChild(children, parent) {
            const showingChildren = children.filter((item) => {
                if (item.hidden) {
                    return false;
                } else {
                    // Temp set(will be used if only has one showing child)
                    this.onlyOneChild = item;
                    return true;
                }
            });

            // When there is only one child router, the child router is displayed by default
            if (showingChildren.length === 1) {
                return true;
            }

            // Show parent if there are no child router to display
            if (showingChildren.length === 0) {
                this.onlyOneChild = {
                    ...parent,
                    path: "",
                    noShowingChildren: true,
                };
                return true;
            }

            return false;
        },
        resolvePath(routePath) {
            // console.log('resolvePath')
            if (this.isExternalLink(routePath)) {
                console.log(routePath);
                return routePath;
            }
            return path.resolve(this.basePath, routePath);
        },
        isExternalLink(routePath) {
            return this.$Utils.isExternal(routePath);
        },
    },
};
</script>

<style scoped lang="scss">
.el-submenu .el-menu-item {
    /* padding: 0 3rem !important; */
    /* padding: 0 !important;*/
    // padding-left: 2.6rem !important;
    min-width: 158px !important;
}
.el-submenu {
    // min-width: 158px !important;
}

// .menu-wrapper {
//     min-width: 158px !important;
// }
.itembadge /deep/ .el-badge__content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #ff3b30;
    margin-left: 8px;
    top: 0;
}
.menuitemnobody {
    position: relative;
    /deep/ .el-badge {
        position: absolute;
        right: 13px;
        top: calc(50% - 9px);
    }
}
/deep/ .el-submenu.is-active .el-submenu__title span {
    color: rgb(0, 122, 255);
}
</style>
