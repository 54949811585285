import axios from 'axios';
import Config from '@/config';
import Utils from '@/utils';
import {
	Message,
	Loading
} from 'element-ui';
import store from '@/store';
import Router from '@/router/index'

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

// loading 标记
let needLoadingRequestCount = 0;

function showFullScreenLoading(message) { // 启用loading
	if (needLoadingRequestCount === 0) {
		startLoading(message);
	}
	needLoadingRequestCount++
}

function tryHideFullScreenLoading() {
	if (needLoadingRequestCount <= 0) return;
	needLoadingRequestCount--;
	if (needLoadingRequestCount === 0) {
		endLoading()
	}
}

let loading;

function startLoading(message = '加载中...') {
	loading = Loading.service({
		lock: true,
		text: message,
		spinner: "el-icon-loading",
		background: "rgba(0, 0, 0, 0.2)"
	});
}

function endLoading() {
	loading.close()
}
//axios.defaults.withCredentials = true;
let instance900C = axios.create({
	baseURL: Config.axiosBaseUrl900C,
	timeout: Config.defaultTimeout,
	//withCredentials: true
});

// request 请求拦截器
instance900C.interceptors.request.use(
	config => {
		// config.cancelToken = source.token; // 全局添加cancelToken
		// config.headers['Content-Type'] = 'application/json; charset=utf-8';
		// config.headers['Cache-Control'] = 'no-cache'
		// if (Config.header) {
		// 	Config.header.map(item => {
		// 		config.headers[item.key] = item.value
		// 	})
		// }
		// 请求前进行拦截, 验证是否已经登录等
		// const token = Utils.getToken();
		// if (token) {

		// 	config.headers['token'] = token;
		// }
		//  config.headers['token'] = token;
		// if (config.contentType) {
		// 	config.headers['Content-Type'] = config.contentType
		// }
		if (config.method === 'get') { //get请求加时间戳，解决 ie11 下不刷新的bug
			let flag = config.url.indexOf('?') !== -1 ? '&' : '?';
			let url = `${config.url}${flag}timestamp=${new Date().getTime()}`;
			config.url = encodeURI(url);
		}
		if (config.showLoading) {
			showFullScreenLoading(config.loadingMessage)
		}
		return config;
	},
	error => {
		
		return Promise.reject(error);
	}
);

// response 响应拦截器
instance900C.interceptors.response.use(
	response => {

		if (response.config.showLoading) {
			tryHideFullScreenLoading()
		}

		let res = {
			status: response.status,
			result: response.data,
			data:response.data.data
		}
		response = null
		if(res.result.code != 0){
			
			/**
			 * 没有商户权限或者登录用户token为空时
			 */
			if(res.result.code == 8001 || res.result.code == 8002 || res.result.code == 2004){
				Utils.removeToken();
				Router.replace({
					path: `/login?msg=${res.result.message}`,
				});
				Message({
					message: res.result.message,
					type: 'error',
					duration: 3 * 1000
				});
				return new Promise(() => {});
			}
			Message({
				message: res.result.message || "服务器出错, 请联系管理员",
				type: 'error',
				duration: 3 * 1000
			});
			throw new Error(res.result.message);
		}
		return res
	},
	error => {
		if (error.config.showLoading) {
			tryHideFullScreenLoading();
		}
		switch (error.response && error.response.status) {
			case 401:
				source.cancel(); // 取消其他正在进行的请求
				Message({
					message: '登录已过期, 请重新登录',
					type: 'error',
					duration: 3 * 1000
				});


				this.$router.replace({
					path: "/login",
				});

				break;
			case 502:
				source.cancel(); // 取消其他正在进行的请求
				Message({
					message: error.response.data.message || "服务器出错, 请联系管理员",
					type: 'error',
					duration: 3 * 1000
				});
				break;
			default:
				Message({
					message: error.response.data.message || "系统出错, 请稍后再试",
					type: 'error',
					duration: 3 * 1000
				});
		}
		if (axios.isCancel(error)) { // 取消请求的情况下，中断Promise调用链
			return new Promise(() => {});
		} else {
			return Promise.reject(error);
		}
	}
);

export default instance900C;
