import Vue from 'vue'
import ElementUI from 'element-ui'
import '@/assets/css/element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import "@/assets/css/font-family.css"
import "@/assets/css/app.css"
import "@/assets/css/sub.css"
import '@/assets/icons'
import '@/assets/iconfont/iconfont.css'
import "@/assets/css/common.css"
import "@/components/charts"

import filters from './utils/filters'
filters(Vue)


// 表格表头吸附效果
import tableSticky from "./utils/tableSticky"
Vue.use(tableSticky)

import getDic from './utils/dic'
import getDay from './utils/getDay'
import decimal from './utils/decimal'
Vue.prototype.$getDic = getDic
Vue.prototype.$getDay = getDay
Vue.prototype.$decimal = decimal

import {
	_post,
	_get,
	_delete,
	_put,
	_patch,
    _post900C,
} from '@/axios/func'
import Utils from "@/utils";
import Config from "@/config";

import App from '@/App'
import router from '@/router'
import store from '@/store'
import BaseVue from "@/views/common/base.vue"

import LeJumptoDetail from './views/components/page/le-jumpto-detail.vue'
Vue.component('le-jumpto-detail', LeJumptoDetail)

import LeSelectLocalSearch from './views/components/form/le-select-local-search.vue'
Vue.component('le-select-local-search', LeSelectLocalSearch)

import LeSelectRemoteSearch from './views/components/form/le-select-remote-search.vue'
Vue.component('le-select-remote-search', LeSelectRemoteSearch)

import LeSelectNumRange from './views/components/form/le-select-num-range.vue'
Vue.component('le-select-num-range', LeSelectNumRange)

import LeDateRange from './views/components/form/le-date-range.vue'
Vue.component('le-date-range', LeDateRange)

import LeInputDistrict from './views/components/form/le-input-district.vue'
Vue.component('le-input-district', LeInputDistrict)

import LeInputDistrictChoose from './views/components/form/le-input-district-choose.vue'
Vue.component('le-input-district-choose', LeInputDistrictChoose)

import LeInputDistrictForm from './views/components/form/le-input-district-form.vue'
Vue.component('le-input-district-form', LeInputDistrictForm)

import LeInputDate from './views/components/form/le-input-date.vue'
Vue.component('le-input-date', LeInputDate)

import LeInput from './views/components/form/le-input.vue'
Vue.component('le-input', LeInput)

import LeInputSuffix from './views/components/form/le-input-suffix.vue'
Vue.component('le-input-suffix', LeInputSuffix)

import LeInputDisable from './views/components/form/le-input-disable.vue'
Vue.component('le-input-disable', LeInputDisable)

import LeInputNum from './views/components/form/le-input-num.vue'
Vue.component('le-input-num', LeInputNum)

import LeInputSlot from './views/components/form/le-input-slot.vue'
Vue.component('le-input-slot', LeInputSlot)

import LeInputIconSearch from './views/components/form/le-input-icon-search.vue'
Vue.component('le-input-icon-search', LeInputIconSearch)

import LeInputNumRange from './views/components/form/le-input-num-range.vue'
Vue.component('le-input-num-range', LeInputNumRange)

import LeInputPriceRange from './views/components/form/le-input-price-range.vue'
Vue.component('le-input-price-range', LeInputPriceRange)

import LeInputPrice from './views/components/form/le-input-price.vue'
Vue.component('le-input-price', LeInputPrice)

import LeSearchForm from './views/components/form/le-search-form.vue'
Vue.component('le-search-form', LeSearchForm)

import LeSwitchBtn from './views/components/form/le-switch-btn.vue'
Vue.component('le-switch-btn', LeSwitchBtn)

import LeInputAddress from './views/components/form/le-input-address.vue'
Vue.component('le-input-address', LeInputAddress)

import LePagview from './views/components/table/le-pagview.vue'
Vue.component('le-pagview', LePagview)


import LeUploadFile from './views/components/form/le-upload-file.vue'
Vue.component('le-upload-file', LeUploadFile)

import LeInputAddressPoint from './views/components/form/le-input-address-point.vue'
Vue.component('le-input-address-point', LeInputAddressPoint)


import LeCompanyAllSelect from './views/components/choose/le-company-all-select.vue'
Vue.component('le-company-all-select', LeCompanyAllSelect)

import LeCompanyUnderSelect from './views/components/choose/le-company-under-select.vue'
Vue.component('le-company-under-select', LeCompanyUnderSelect)

import LeCompanyNoAgencySelect from './views/components/choose/le-company-noagency-select.vue'
Vue.component('le-company-noagency-select', LeCompanyNoAgencySelect)

import LeCompanyUnderandparentSelect from './views/components/choose/le-company-underandparent-select.vue'
Vue.component('le-company-underandparent-select', LeCompanyUnderandparentSelect)

import Breadcrumb from './components/layout/components/bread-crumb.vue'
Vue.component('bread-crumb', Breadcrumb)


import LeSurveyChoose from './views/components/choose/le-survey-choose.vue'
Vue.component('le-survey-choose', LeSurveyChoose)
import LeProjectChoose from './views/components/choose/le-project-choose.vue'
Vue.component('le-project-choose', LeProjectChoose)
import LePriceChoose from './views/components/choose/le-price-choose.vue'
Vue.component('le-price-choose', LePriceChoose)
import LeAddressChoose from './views/components/choose/le-address-choose.vue'
Vue.component('le-address-choose', LeAddressChoose)


import LeSwitch from './views/components/form/le-switch.vue'
Vue.component('le-switch', LeSwitch)

import LeEchartsLine from './views/components/echarts/le-echarts-line.vue'
Vue.component('le-echarts-line', LeEchartsLine)

import LePreviewImg from './views/components/poppup/le-preview-img.vue'
Vue.component('le-preview-img', LePreviewImg)

import LeUploadFileImgLY from './views/components/form/le-upload-file-img-ly.vue'
Vue.component('le-upload-file-img-ly', LeUploadFileImgLY)


import LeUploadFileImg from './views/components/form/le-upload-file-img.vue'
Vue.component('le-upload-file-img', LeUploadFileImg)

import LeServicePrivacy from './views/components/form/le-service-privacy.vue'
Vue.component('le-service-privacy', LeServicePrivacy)

import LeExportBtn from './views/components/btn/le-export-btn.vue'
Vue.component('le-export-btn', LeExportBtn)

import JdInputPrice from './views/components/formcy/jd-input-price.vue'
Vue.component('jd-input-price', JdInputPrice)

import JdInputIntnum from './views/components/formcy/jd-input-intnum.vue'
Vue.component('jd-input-intnum', JdInputIntnum)

import JdSelectMuti from './views/components/formcy/jd-select-muti.vue'
Vue.component('jd-select-muti', JdSelectMuti)

import JdDatetimeRange from './views/components/formcy/jd-datetime-range.vue'
Vue.component('jd-datetime-range', JdDatetimeRange)

import exportResultList from './views/components/formcy/export-result-list.vue'
Vue.component('export-result-list', exportResultList)

import jdInputCondition from './views/components/formcy/jd-input-condition.vue'
Vue.component('jd-input-condition', jdInputCondition)

import jdExportDialog from './views/components/formcy/jd-export-dialog.vue'
Vue.component('jd-export-dialog', jdExportDialog)

import jdSelectInput from './views/components/formcy/jd-select-input.vue'
Vue.component('jd-select-input', jdSelectInput)

import jdOtherDateRange from './views/components/formcy/jd-otherDate-range.vue'
Vue.component('jd-other-date-range', jdOtherDateRange)

import leCompanySoloSelect from './views/components/choose/le-company-solo-select.vue'
Vue.component('le-company-solo-select', leCompanySoloSelect)

import jdMdPrice from './views/components/formcy/jd-md-price.vue'
Vue.component('jd-md-price', jdMdPrice)

import LeStationList from './views/components/poppup/le-station-list.vue'
Vue.component('le-station-list', LeStationList)


Vue.use(ElementUI, {
	size: 'small'
});

/**
 * 文件导出
 */
 Vue.prototype.$exfile=(exportParams,callback)=>{
	store.dispatch('exportFile/setExportDialog',true)
	store.dispatch('exportFile/setExportParams',{
		code: exportParams.code,
		fileName: exportParams.fileName,
		startTime: exportParams.startTime,
        endTime: exportParams.endTime,
		params: {
			...exportParams.params
		}
	})
 }



import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.prototype.$Axios = {
	_post,
	_get,
	_delete,
	_put,
	_patch,
    _post900C,
};
Vue.prototype.$Utils = Utils;
Vue.prototype.$Config = Config;
//按钮权限
Vue.prototype.$_has = function(value) {
	let isExist = false;
	let buttonpermsStr = store.state.menu.menuList
	if (buttonpermsStr == undefined) {
		return false;
	}
	if (buttonpermsStr.indexOf(value) > -1) {
		isExist = true;
	}
	return isExist;
	// return true
};


Vue.prototype.$eventBus = new Vue()


const ChildVue = Vue.extend(BaseVue);
new ChildVue({
	el: '#app',
	router,
	store,
	render: h => h(App),
}).$mount('#app');
