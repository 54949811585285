// 字典	
import {
	_post,
	_get
} from "@/axios/func";
import Config from "@/config";

function getDic (type, useType='filter') {
    return new Promise(async (resolve, reject) => {
        let dic = JSON.parse(window.sessionStorage.getItem('dic'))
        if(dic && dic.length) {
            let targetDic = dic.find(res=>{
                return res.type == type
            })
            let dicList = targetDic&&targetDic.dicList?targetDic.dicList:[]
            dicList = dicList.map(item=>{
                if(useType == 'select'){
                    return {
                        label: item.name,
                        value: item.code
                    }
                }else{
                    return {
                        text: item.name,
                        value: item.code
                    }
                }
                
            })
            resolve(dicList)
        }else{
            let arg = {
                url: Config.apiUrl.getDictionary,
                showLoading:false,
            };
            const {
                result
            } = await _get(arg)
            if (result.code === 0 && result.data) {
                let targetDic = result.data.find(res=>{
                    return res.type == type
                })
                window.sessionStorage.setItem('dic',JSON.stringify(result.data))
                let dicList = targetDic&&targetDic.dicList?targetDic.dicList:[]
                dicList = dicList.map(item=>{
                    if(useType == 'select'){
                        return {
                            label: item.name,
                            value: item.code
                        }
                    }else{
                        return {
                            text: item.name,
                            value: item.code
                        }
                    }
                })
                resolve(dicList)
            } else {
                resolve([])
            }
        }
    })
    
}

export default getDic