<template>
    <div class="a-flex-rfsc">
        <div class="selectBox a-flex-cfsfs">
            <el-radio-group v-model="checkProvince" @change="provinceFun()">
                <div class="selectBox-item a-flex-rfsc" v-for="(item,index) in c_province" :key="index">
                    <el-radio :label="item">{{item.name}}</el-radio>
                </div>
            </el-radio-group>
        </div>
        <div class="selectBox a-flex-cfsfs">
            <el-checkbox-group v-model="checkedCity" @change="cityChange">
                <div class="selectBox-item a-flex-rfsc" v-for="(item,index) in c_ctiy" :key="index">
                    <el-checkbox :label="item">{{ item.name }}</el-checkbox>
                </div>
            </el-checkbox-group>
        </div>
        <span class="el-icon-d-arrow-right a-c-blue" style="margin-right:16px"></span>
        <div class="selectBox">
            <div class="selectBox-item a-flex-rfsc" v-for="(item,index) in showProvince" :key="index">
                <el-checkbox :checked="true"  @change="clearCity(index)">
                    <span>{{item.name}}</span>
                </el-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
    let _this
    export default {
        props: ['getCityData','checkDatas'],
        data() {
            return {
                checkAll: false,//市-全选
                isIndeterminate: true,
                province:[],//省列表
                c_province:[],//省列表-转换后
                ctiy:[],//市列表
                c_ctiy:[],//市列表-转换后
                checkProvince:'',//选中-省
                showProvince:[],//显示选中-省列表
                checkedCity: [],//选中-市列表
                reqData:[],
                allProvice:false,//全国
            }
        },
        created () {
            _this=this;
            this.getProvince();
            if(this.checkDatas){
                this.checkDatas(this.getPreData)
            }
            
        },
        methods: {
            //获取传入数据
            getPreData(res){
                this.showProvince=res?res:[]
            },
            //数据转换
            dataConvert(dataList){
                let returnDa=[];
                if(dataList&&dataList.length){
                    dataList.map(item=>{
                        returnDa.push({
                            code:item.id,
                            name:item.name
                        })
                    })
                }
                return returnDa;
            },
            //获取省列表
            getProvince(){
                this.$Axios._post({
                    url: this.$Config.apiUrl.bikeList,
                    method: "post",
                    params: {}
                }).then(res=>{
                    if(res.result.code==0){
                        _this.province=res.result.data;
                        _this.c_province=_this.dataConvert(res.result.data)
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            provinceFun() {//选择省
                this.checkedCity=[]
                this.isIndeterminate = true;
                this.checkAll=false;
                this.getCity(this.checkProvince.code) 
            }, 
            getCity(id){
                this.$Axios._post({
                    url: this.$Config.apiUrl.batterybList,
                    method: "post",
                    params: {
                        bikeId: id
                    }
                }).then(res=>{
                    if(res.result.code==0){
                        _this.ctiy=res.result.data;
                        _this.c_ctiy=_this.ctiy.map(item=>{
                            return {
                                code: item.id,
                                name: item.batteryVoltage
                            }
                        })
                        let showProvinceArray=[];
                        this.showProvince.map(item=>{
                            showProvinceArray.push(item)
                        })
                        let checkCity=[]
                        showProvinceArray.map((item,index)=>{
                            _this.c_ctiy.map((c_item,c_index)=>{
                                if(item.code==c_item.code){
                                    checkCity.push(c_item)
                                }
                            })
                        })
                        _this.checkedCity=checkCity;
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            // cityAllChange(val) {//全选城市
            //     this.checkedCity = val ? this.c_ctiy : [];
            //     let showProvinceArray=[];
            //     this.showProvince.map(item=>{
            //         showProvinceArray.push(item)
            //     })
            //     this.c_ctiy.map((item,index)=>{
            //         showProvinceArray.map((p_item,p_index)=>{
            //             if(p_item.code==item.code){
            //                 showProvinceArray.splice(p_index,1)
            //             }
            //             if(p_item.code==_this.checkProvince.code){
            //                 showProvinceArray.splice(p_index,1)
            //             }
            //         })
            //     })
            //     if(val){
            //         this.isIndeterminate = false;
            //         this.checkAll=true;
            //         showProvinceArray.push(_this.checkProvince)
            //     }else{
            //         this.isIndeterminate = true;
            //         this.checkAll=false;
            //     }
            //     if(this.c_ctiy.length>0){
            //         this.showProvince=showProvinceArray
            //         this.getCityData(this.showProvince)
            //     }
            // },
            cityChange(value) {//选中城市
                let showProvinceArray=[];
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.c_ctiy.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.c_ctiy.length;
                this.showProvince.map(item=>{
                    showProvinceArray.push(item)
                })
                this.c_ctiy.map((item,index)=>{
                    showProvinceArray.map((p_item,p_index)=>{
                        if(p_item.code==item.code){
                            showProvinceArray.splice(p_index,1)
                        }
                        // if(p_item.code==_this.checkProvince.code){
                        //     showProvinceArray.splice(p_index,1)
                        // }
                    })
                })
                if(value.length){
                    value.map((item,index)=>{                            
                        showProvinceArray.push({code:item.code,name:_this.checkProvince.name+'-'+item.name})
                    })
                }
                // if(checkedCount === this.c_ctiy.length){//城市全选                  
                //     let isHaveP=true
                //     showProvinceArray.map(function(item,index){
                //         if(item.code==_this.checkProvince.code){
                //             isHaveP=false;
                //         }
                //     })
                //     if(isHaveP){
                //         showProvinceArray.push(_this.checkProvince)
                //     }
                // }else{//城市部分选中
                //     if(value.length){
                //         let isHave_c=true;                       
                //         value.map((item,index)=>{                            
                //             showProvinceArray.push({code:item.code,name:_this.checkProvince.name+'-'+item.name})
                //         })
                //     }
                // }
                _this.showProvince=showProvinceArray;
                this.getCityData(this.showProvince)
            },
            //取消选中
            clearCity(index){
                let showProvinceList=[];
                this.showProvince.map(item=>{
                    showProvinceList.push(item)
                })
                if(showProvinceList[index].code==_this.checkProvince.code){
                    this.checkedCity=[]
                    this.isIndeterminate = true;
                    this.checkAll=false;
                }else{
                    let lsList=[]
                    this.checkedCity.map((item,index)=>{
                        lsList.push(item)
                    })
                    lsList.map((c_item,c_index)=>{
                        if(c_item.code==showProvinceList[index].code){
                            lsList.splice(index,1)
                        }
                    })
                    this.checkedCity=lsList
                }
                showProvinceList.splice(index,1)
                 _this.showProvince=[]
                 setTimeout(() => {
                     _this.allProvice=false
                    _this.showProvince=showProvinceList
                    _this.getCityData(_this.showProvince)
                 }, 10);
            }
        },
    }
</script>

<style lang="scss" scoped>
.selectBox{
    width: 180px;
    height: 180px;
    border-radius: 4px;
    overflow-y: auto;
    border: 1px solid #DCDFE6;
    margin-right: 16px;
    padding: 8px 0;
}
.selectBox-item{
    height: 30px;
    padding: 0 15px;
}
</style>