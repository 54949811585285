export default {
    namespaced: true,
    state: () => ({
        sidebarOpened: true,
    }),
    mutations: {
        triggerSidebarOpened(state) {
            state.sidebarOpened = !state.sidebarOpened;
        },
    },
    actions: {},
};
