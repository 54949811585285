<template>
	<el-dialog title="选择项目收益" :visible.sync="dialogVisible">
		<div class="a-flex-rfsc">
			<le-input-icon-search label='方案名称' placeholder="请输入方案名称" v-model="pageParam.params.name" style="padding: 0 !important"></le-input-icon-search>
		</div>
		<div class="a-mt-24">
			<le-pagview @setData="setTableData" :pageParam="pageParam">
				<el-table :data="projectList"  height="425" >
					<el-table-column property="name" label="项目名称" ></el-table-column>
					<el-table-column property="companyName" label="所属商户" ></el-table-column>
					<el-table-column property="stateText" label="项目状态" ></el-table-column>
					<el-table-column property="electricCompanyName" label="电费承担方"></el-table-column>
					<el-table-column property="startTimeText" label="生效时间"></el-table-column>
					<el-table-column label="操作" width="80">
						<template slot-scope="{ row }">
							<el-button type="info" plain @click="handlerChooseIncome(row)">选择</el-button>
						</template>
					</el-table-column>
				</el-table>
			</le-pagview>
		</div>
		
	</el-dialog>
</template>
<script>
	/**
	 * 现勘选择
	 */
	export default {
		props: {
			visible:{
				required:true,
				type:Boolean,
				default:false,
			},
			incomeId:{
				required:false,
			},
			incomeName:{
				required:false,
			},
			status:{
				type:Number
			},
		},
		data() {
			return {
				dialogVisible:false,
				pageParam: {
					url: this.$Config.apiUrl.getJoinDistriSelectList,
					method: "post",
					params: {
						name:'',
						status:this.status
					},
				},
				projectList:[]
			};
		},
		watch:{
			visible:{
				handler(val){
					this.dialogVisible = val;
				},
			},
			dialogVisible:{
				handler(val,oldval){
					if(val != oldval){
						this.$emit("update:visible",val)
					}
				}
			},
		},
		mounted() {
		},
		methods: {
			handlerChooseIncome(item){
				this.dialogVisible = false
				this.$emit("update:incomeId",item.id)
				this.$emit("update:incomeName",item.name)
				this.$emit("change",item)
			},
			setTableData(data) {
				this.projectList = data;
			},
			
		},
	};
</script>
