import hichar from "@/config/api/hichar";

const brandEnv = 'jd'
export default {
	projectName: '',
	projectTitle: '',
	baseUrl: '',
	brand: brandEnv=='jd'?'毛豆充':'雅迪充电',
	appId: brandEnv=='jd'?'hichar.company.web.jd':'hichar.company.web.jd',
	header: [{
		key: 'appId',
		value:  brandEnv=='jd'?'hichar.company.web.jd':'hichar.company.web.jd'
	}],
	tokenKey: 'JHDesignToken',
	timeout: 1000 * 60 * 60, // 1个小时
	defaultTimeout: 1000 * 60 * 5, // 5分钟

	//axiosBaseUrl: process.env.NODE_ENV === 'production' ? 'https://api.v2.hichar.cn/' : 'https://zjp.meishutech.com/charging-api-new-zjp/', //
	axiosBaseUrl: require('../config/env.' + process.env.NODE_ENV).baseUrl, //
    axiosBaseUrl900C: require('../config/env.' + process.env.NODE_ENV).baseUrl900C, //
	//axiosBaseUrl: process.env.NODE_ENV === 'production' ? 'http://zjp.meishutech.com/charging-api-new-wsx/' : 'http://zjp.meishutech.com/charging-api-new-wsx/', //
	//axiosBaseUrl: process.env.NODE_ENV === 'production' ? 'http://zjp.meishutech.com/charging-api-new-wsx/' : 'http://api.v2.yd.hichar.cn/'
    discountTypeDic: {
        2: '红包',
        3: 'VIP',
        4: '毛豆',
        5: '优惠券',
        1: '活动折扣',
        6: '福利站点',
    }, //订单优惠类型
	apiUrl: {
		...hichar,
	},
};
