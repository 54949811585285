<template>
	<div class="a-flex-rfsc">
        <div class="s-search-label" style="white-space: nowrap;" v-show="label">
			<div>{{label}}</div>
		</div>
		<el-input
			:placeholder="placeholder" :disabled="disabled?true:false" :value="value" @input="handlerChange" @blur='handlerBlur' :maxlength="maxlength">
            <template slot="prepend">¥</template>
		</el-input>
	</div>
	
</template>

<script>
    import util from '@/utils/util'
	export default {
		props:{
			label:{
				type:String
			},
			value:{
				required:true,
				default:''
			},
			placeholder:{
				type:String,
				default:'请输入'
			},
			maxlength:{
				default: 11
			},
			disabled:{
				default: false
			}
		},
		data() {
			return {
			}
		},
		methods:{
			handlerChange(e){
                e = util.checkMoney(e)
				this.$emit('input',e)
			},
            handlerBlur () {
                this.$emit('blur')
            }
		}
	}
</script>

<style lang="scss" scoped>
    /deep/ .el-input-group__prepend{
        padding: 0 12px !important;
    }
</style>
