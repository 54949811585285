<template>
	<div class="a-flex-rfsc">
        <div class="s-search-label" style="white-space: nowrap;" v-show="label">
			<div>{{label}}</div>
		</div>
		<el-input
			:placeholder="placeholder" :maxlength='maxlength' :value="value" @input="handlerChange">
		</el-input>
	</div>
	
</template>

<script>
    import util from '@/utils/util'
	export default {
		props:{
			label:{
				type:String
			},
			value:{
				required:true,
				default:''
			},
			placeholder:{
				type:String,
				default:'请输入'
			},
            maxlength: {
                default: 20
            }
		},
		data() {
			return {
			}
		},
		methods:{
			handlerChange(e){
                e = util.checkInteger(e)
				this.$emit('input',e)
			},
		}
	}
</script>
