<template>
	<div class="a-flex-cfsfs a-flex-1 aaaa">
		<slot name="headerLabel" :val="totalVal" ></slot>
        <slot name="headerContent" :val="footerData" ></slot>
		<div class="a-flex-1 a-w-100" v-loading='isLoading'>
			<slot></slot>
		</div>
		<div style="height:34px;" v-if="isFixed"></div>
        <div class="a-w-100  a-mt-12" :class="isFixed?'fixBottom':''">
            <scroll-bar :table-ref="tableRef"></scroll-bar>
			<div class="a-flex-rsbc">
				<slot name="footerLabel" :val="totalVal" ></slot>
				<slot name="footerContent" :val="footerData" ></slot>
				<div class="a-flex-rfec a-flex-1">
					<el-pagination :current-page="pageNum" :pager-count="pagerCount" :page-size.sync="pageSize" :page-sizes="pageSizeArr" :layout="layout" :total="total" background
						@current-change='handleCurrentChange' @size-change='handleSizeChange'>
					</el-pagination>
				</div>
			</div>
        </div>
	</div>
</template>

<script>
import scrollBar from './scrollBar.vue';
export default {
  components: { scrollBar },
    props: {
        pageParam: {
            type: Object,
            required: true,
            validator: (param) => {
                if (!param.url) {
                    console.log("pageview 参数缺少url");
                    return false;
                }
                if (!param.method) {
                    console.log("pageview 参数缺少method(post|get)");
                    return false;
                }
                if (!param.params) {
                    console.log("pageview 参数缺少params");
                    return false;
                }
                return true;
            }
        },
        isFixed: { // 分页组件是否固定在页面底部
            default: false
        },
		tableRef: { // 虚拟滚动条目标表格
			require: true
		},
        pagerCount: {
            default: 7,
            type: Number
        },
        pageSizeDefault: {
            default: 20
        },
        pageSizeArr: {
            default: ()=>{
                return [10, 20, 50, 100]
            }
        },
        tableLoading: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            total: 1,
            totalVal: -1,
            pageNum: 1,
            pageSize: 20,
            knowTotal: false,
            freshCtrl: -1,
            footerData: '',
            layout: "sizes, prev, pager, next, jumper",
            isLoading: false
        };
    },
    mounted () {
        
    },
    beforeMount () {
        this.pageSize = this.pageSizeDefault
    },
    created () {
        
    },
    watch: {
        total: {
            handler(val) {
                if (val == -1) {
                    this.layout = "sizes, prev, pager, next, jumper";
                }
                else {
                    this.layout = "sizes, prev, pager, next, total, jumper";
                }
            }
        },
        pageParam: {
            handler(val) {
                if (val && val.url && val.method && val.params) {
                    if (val.freshCtrl) {
                        if (this.freshCtrl != val.freshCtrl) {
                            this.freshCtrl = val.freshCtrl;
                            // this.pageNum = 1
                            this.$nextTick(()=>{
                                this.getTableData();
                            })
                        }
                    }
                    else {
                        // this.pageNum = 1
                        this.$nextTick(()=>{
                            this.getTableData();
                        })
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
    // layout () {
    // 	if(this.total = -1) {
    // 		return 'sizes, prev, pager, next, jumper'
    // 	}else{
    // 		return 'sizes, prev, pager, next, total, jumper'
    // 	}
    // }
    },
    methods: {
        getTableData() {
            if(this.tableLoading){
                this.isLoading = true
            }
            let func = this.$Axios._post;
            if (this.pageParam.method.toLowerCase() == "get") {
                func = this.$Axios._get;
            }
            func({
                ...this.pageParam,
                params: {
                    ...this.pageParam.params,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                }
            }).then(res => {
                if(this.tableLoading){
                    this.isLoading = false
                }
                let pageData
                if(res.result.data.pageData){
                    pageData = res.result.data.pageData
                }else if(res.result.data.list){
                    pageData = res.result.data
                }else{
                    pageData = res.result
                }
                // 列表额外数据
                if(res.result.data.count){
                    this.footerData = res.result.data.count
                }else{
                    this.footerData = ''
                }
                this.$emit("setData", pageData.list ? pageData.list : pageData, this.footerData);
                this.$emit("update:total", pageData.total);
                this.$emit('pageChange', { pageSize: this.pageSize, pageNum: this.pageNum })
                /**
                 * 有分页总数时
                 */
                if (pageData.total != -1) {
                    this.total = pageData.total;
                    this.totalVal = pageData.total;
                }
                else {
                    /**
                     * 无分页总数时
                     */
                     this.totalVal = -1
                    if (pageData.length < this.pageSize) {
                        this.total = this.pageNum * this.pageSize - (this.pageSize - pageData.length);
                        this.knowTotal = true;
                    }
                    else if (!this.knowTotal) {
                        let total = (this.pageNum + 1) * this.pageSize;
                        if (total > this.total) {
                            this.total = total;
                        }
                    }
                }
            }).catch(err=>{
                if(this.tableLoading){
                    this.isLoading = false
                }
            });
        },
        // 行数切换
        handleSizeChange(value) {
            this.pageSize = value;
            this.pageNum = 1;
            this.getTableData();
        },
        // 页码切换
        handleCurrentChange(value) {
            this.pageNum = value;
            this.getTableData();
        },
    },
}
</script>

<style>
	.aaaa:after{
        content: '';
        display: block;
        clear: both;
    }
	.fixBottom{
		width: auto;
		position: fixed;
		left: 157px;
		right: 0;
		bottom: 0;
		background: #fff;
		padding: 0px 52px 10px;
		z-index: 2000;
		border-top: 1px solid #EBEEF5;
	}
</style>
