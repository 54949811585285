import {
	_post,
	_get
} from "@/axios/func";
import Config from "@/config";
import Utils from "@/utils";
import getMenuList from '@/utils/menu';

// let todonumObj={
//     "商户入驻":0,
//     "站点":0,
//     "代理":0,
//     "提现":0,
//     "电费":0,
//     "收益方案":0,
//     "工单":0,
//     "定价":0,
//     "采购订单":0,
//     "totalNum":0
// }
let todonumObj={
	todolist:
		[{
			key: 1,
			title: '商户入驻',
			num:0
		}, {
			key: 2,
			title: '站点建设',
			num:0
		}, {
			key: 3,
			title: '代理合同',
			num:0
		}, {
			key: 4,
			title: '提现申请',
			num:0
		},
		{
			key: 5,
			title: '电费申请',
			num:0
		},
		{
			key: 6,
			title: '收益方案',
			num:0
		},
		{
			key: 7,
			title: '工单',
			num:0
		}, {
			key: 8,
			title: '定价',
			num:0
		}, {
			key: 9,
			title: '采购订单',
			num:0
		}],
		totalNum:0
	
}
const state = {
    todonum:{
        ...todonumObj
    }
	
}

const mutations = {
	setTodoNum: (state, todonumObj) => {
		state.todonum = todonumObj
	}
}

const actions = {

	getTodoNum({
		commit
	}) {
		return new Promise(async (resolve, reject) => {
			try {
				let arg = {
					url: Config.apiUrl.getWithDoneDealNum,
					params: {},
					showLoading:false
				};
				const {
					result
				} = await _post(arg)

				if (result.code === 0) {
					const data = result.data;
                   
                    let obj={todolist:[],todolist:0}
				
					if(data.result){
						data.result.map((item,index)=>{
							todonumObj.todolist.map((oitem,oindex)=>{
								if(oitem.key==item.keyType){
									oitem.num=item.num
								}
							})
							
						})
					}
			
					todonumObj.totalNum=data.totalNum
         
					commit('setTodoNum', todonumObj)
					resolve(todonumObj)
				}
				
			} catch (err) {
				reject(err);
			}
		});
	},



}


export default {
	namespaced: true,
	state,
	actions,
	mutations
}
