<template>
    <div class="a-w-100 a-h-100">
        <div class="main-container a-w-100 a-h-100">
            <navbar />
            <div class="a-flex-rfsfs mainBoxRight" :class="[sidebarOpened ? 'openSidebar' : 'hideSidebar']" >
                <Sidebar></Sidebar>
                <div class="containBox a-flex-cfsfs a-flex-1">
                    <tags-view />
                    <app-main />
                </div>
            </div>
        </div>
        <jd-export-dialog></jd-export-dialog>
    </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, TagsView, MenuLeft } from "./components";

import { mapGetters, mapState, mapActions } from "vuex";

export default {
    name: "Layout",
    components: {
        Navbar,
        Sidebar,
        AppMain,
        TagsView,
        MenuLeft,
    },
    computed: {
        ...mapState({
            routeHis: (state) => state.menu.routeHis,
            sidebarOpened: (state) => state.hamburger.sidebarOpened,
        }),
        sidebar() {
            return this.$store.state.app.sidebar;
        },
        device() {
            return this.$store.state.app.device;
        },
    },
    watch: {
        // $route(to, from) {
        // 	console.log('$route change', to.matched)
        // 	this.addRouteHis(to.matched)
        // }
    },
    methods: {
        // ...mapActions({
        // 	addRouteHis: 'menu/addRouteHis'
        // }),
        handleClickOutside() {
            this.$store.dispatch("CloseSideBar", {
                withoutAnimation: false,
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.containBox {
    width: calc(100% - 270px);
}

.mainBoxRight {
    position: relative;
    //position: fixed;
    top: 64px;
    height: calc(100% - 64px);

    // /deep/ .el-scrollbar__view > .el-menu {
    //     transition: width 0.28s;
    // }
}

.main-container {
    position: relative;
}

.el-scrollbar /deep/ .el-scrollbar__bar {
    display: none !important;
}

.el-scrollbar /deep/ .my-menu {
    overflow-x: hidden !important;
}

.openSidebar /deep/ .el-scrollbar__view > .el-menu {
    width: 158px;
}
.hideSidebar /deep/ .el-scrollbar__view > .el-menu.el-menu--collapse {
    width: 42px;
}

.openSidebar /deep/ .el-submenu__icon-arrow {
    position: absolute;
    top: calc(50% - 5px);
    right: 15px;
    margin-top: 0;
}
.hideSidebar /deep/ .el-submenu__icon-arrow {
    display: none;
}
.hideSidebar /deep/ .el-badge {
    display: none;
}
</style>
