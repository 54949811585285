import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Utils from '@/utils';
import routes from './routes'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import {
	Message
} from 'element-ui'

Vue.use(VueRouter);
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}
const router = new VueRouter({
	mode: 'hash',
	scrollBehavior: () => ({
		y: 0
	}),
	routes: routes
});

const whiteList = ['/login','/data']; // 不重定向白名单

router.beforeEach(async (to, from, next) => {
	
	// 保存上次的路由
	store.dispatch('setLastView', from);
	NProgress.start();
	let href = window.location.href;

	//判断页面是否是从其他地方跳转过来,并存url上获取token
	if (href.indexOf("token") != -1) {
		let token = href.match(/(?:token\=)([^\=\&\#\/]+)/)[1]
		Utils.setToken(token, 1)
	}

	if (Utils.getToken()) {
		
		/**
		 * 判断用户登录状态是否有效
		 */
		if(!store.state.user.id){
			try{
				await  store.dispatch('user/getUserInfo')
			}catch(err){
				Utils.removeToken()
				next(`/login`); 
				NProgress.done();
				return;
			}
		}
		/**
		 * 判断当前所属商户是否有效
		 */
		if(!store.state.company.company.id){
			let company = await store.dispatch('company/getDefaultCompanyInfo')
			if(!company){
				next(`/login`); 
				NProgress.done();
				return;
			}
		}
		
		/**
		 * 动态加载菜单
		 */
		let menu = store.state.menu.menu;
		if(!menu || menu.length == 0){ //!menu || menu.length == 0
			let menuData = await store.dispatch('menu/getMenu')
			if(!menuData || menuData.length ==0){
				Message.error('您暂无访问菜单, 请联系管理员')
				Utils.removeToken()
				next(`/login`); 
				NProgress.done();
				return;
			}
		}
		
		if (to.path === '/login') {
			//如果token存在，跳转的路径是登录页面，就让回到首页
			next({
				path: '/home/index'
			});
			NProgress.done() // if current page is dashboard will not trigger afterEach hook, so manually handle it
		} else {
			
			/**
			 * 获取待办事项数量
			 */
			store.dispatch('todonum/getTodoNum')
			/**
			 * 获取我的消息数量
			 */
			store.dispatch('newsnum/getNewsNum')
			
			/**
			 * 添加路由历史
			 */
			store.dispatch('menu/addRouteHis',to)
			
			/**
			 * 刷新商户信息
			 */
			store.dispatch('company/getCompanyInfo')
			
			
			next()
			
		}
	} else {
	
		if (whiteList.indexOf(to.path) !== -1) {
		    next()
		} else {
            Message.error('登录状态已过期, 请重新登录')
		    next(`/login`); // ?redirect=${to.fullPath} 否则全部重定向到登录页
		    NProgress.done()
		}
	}
});

router.afterEach((to,from) => {
	NProgress.done() // 结束Progress
	// console.log(from)
	// console.log(this)
	// debugger
});



export default router;
