<template>
	<div class="a-flex-rfsc a-ptb-12">
        <div class="s-search-label a-flex-rfec " style="width: 82px;" v-show="label">
			<div>{{label}}</div>
		</div>
        <!-- form.day=form.day.replace(/^(0+)|[^\d]+/g,'') -->
        <el-input 
            class="a-ml-20 a-flex-1" 
            style="width:240px"
            :placeholder="placeholder" 
            :value="value" 
            @input="handlerChange">
            <el-select :value="condition" @change="conditionChange" slot="prepend" placeholder="请选择" style="width:100px">
                <el-option :label="item.label" :value="item.value" v-for="(item,index) in options" :key="index"></el-option>
            </el-select>
            <div slot="append" v-if="showAppend">
                <slot></slot>
            </div>
        </el-input>
	</div>
	
</template>

<script>
    import util from '@/utils/util'
	export default {
		props:{
			label:{
				type:String
			},
			value:{
				default: ''
			},
            condition:{
				default: 1
			},
            inputType:{
                default: 'integer', //integer -- 整数； money -- 金额
            },
			placeholder:{
				type:String,
				default:'请输入'
			},
            showAppend: {
                type: Boolean,
                default: false
            },
            options: {
                default: ()=>{
                    return [{
                        label: '大于等于',
                        value: 1
                    },{
                        label: '小于',
                        value: 2
                    }]
                }
            }
		},
		data() {
			return {

			}
		},
		methods:{
			handlerChange (e) {
                if(this.inputType == 'integer'){
                    e = e.replace(/^(0+)|[^\d]+/g,'')
                }else if(this.inputType == 'money'){
                    e = util.checkMoney(e)
                }
				this.$emit('update:value',e)
			},
            conditionChange (e) {
                this.$emit('update:condition',e)
            }
		}
	}
</script>
<style scoped>

</style>